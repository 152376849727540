import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import { States, Api } from '@core/types';
import ProjectRow from './ProjectRow';
import { projectActionCreators } from '@redux/projects';
import { connect } from 'react-redux';
import { usePassportContext } from '@tti/passport';
import CreateProject from './CreateProject';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import useConstructor from '@hooks/useConstructor';
import PDFRow from './PDFRow';
import { PrinterApprovalStatus } from '@core/enums/project';

interface IProps {
  project: States.IProjectState;
  fetchProjects: (params: Api.IFetchProjectsRequest) => void;
  fetchUserPdfs: (params: Api.IFetchUserPdfsRequest) => void;
}

const ProjectList: FunctionComponent<IProps> = ({ project, fetchProjects, fetchUserPdfs }) => {
  const [showCreateProject, setShowCreateProject] = useState(false);
  const { passportContext } = usePassportContext();
  const [tab, setTab] = useState<number>(0);
  useConstructor(() => fetchProjects({ bearerToken: passportContext.bearerToken || '' }));
  useConstructor(() => fetchUserPdfs({ bearerToken: passportContext.bearerToken || '', status: PrinterApprovalStatus.Approved }));
  useConstructor(() => fetchUserPdfs({ bearerToken: passportContext.bearerToken || '', status: PrinterApprovalStatus.Rejected }));

  if (!project) {
    return null;
  }

  return (
    <Fragment>
      <div className="tabs">
        <div className="tabs__container">
          <button className={`tab ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>
            Previous Projects
          </button>
          <button className={`tab ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
            Approved PDFs
          </button>
          <button className={`tab ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>
            Rejected PDFs
          </button>
        </div>
        <div className="tabs__create">
          <button className="btn btn--inline" onClick={() => setShowCreateProject(true)}>
            Create New Project
          </button>
        </div>
      </div>
      <CreateProject visible={showCreateProject} setIsVisible={setShowCreateProject} />
      <div className="projects">
        {tab === 0 && (
          <TransitionGroup>
            {project.projects.map(x => (
              <CSSTransition key={x.projectGUID} classNames="fade-in" timeout={300}>
                <ProjectRow key={x.projectGUID} project={x} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
        {tab === 1 && (
          <TransitionGroup>
            {project.approvedPDFs.pdfs.map(x => {
              let description = '';

              if (x.printerAppovalStatusUpdatedByUser !== null) {
                description = `Approved By: ${x.printerAppovalStatusUpdatedByUser.userFirstName} ${x.printerAppovalStatusUpdatedByUser.userLastName}`;
              }

              return (
                <CSSTransition key={x.pdfUrl} classNames="fade-in" timeout={300}>
                  <PDFRow
                    key={x.pdfUrl}
                    title={x.project.projectName}
                    url={x.pdfUrl}
                    date={`Created ${x.createdAtISO}`}
                    description={description}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        )}
        {tab === 2 && (
          <TransitionGroup>
            {project.rejectedPDFs.pdfs.map(x => {
              let description = '';

              if (x.printerAppovalStatusUpdatedByUser !== null) {
                description = `Rejected By: ${x.printerAppovalStatusUpdatedByUser.userFirstName} ${x.printerAppovalStatusUpdatedByUser.userLastName}`;
              }

              return (
                <CSSTransition key={x.pdfUrl} classNames="fade-in" timeout={300}>
                  <PDFRow
                    key={x.pdfUrl}
                    title={x.project.projectName}
                    url={x.pdfUrl}
                    date={`Created ${x.createdAtISO}`}
                    description={description}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
});

const mapDispatchToProps = {
  fetchProjects: (params: Api.IBaseAuthorisedRequest) => projectActionCreators.fetchProjects(params),
  fetchUserPdfs: (params: Api.IFetchUserPdfsRequest) => projectActionCreators.fetchUserPdfs(params),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectList);
