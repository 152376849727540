import { PrinterApprovalStatus } from '@core/enums/project';
import { States } from '@core/types';
import { ReportActionTypes, FETCH_REPORTS, FETCH_REPORTS_ERROR, FETCH_REPORTS_SUCCESS } from './actions';

const initialState: States.IReportState = {
  errors: [],
  printsByUser: [],
  printsByCulture: [],
  downloadsByUser: [],
  downloadsByCulture: [],
};

function reportReducer(state: States.IReportState = initialState, action: ReportActionTypes): States.IReportState {
  switch (action.type) {
    case FETCH_REPORTS: {
      const newState = { ...state };
      return newState;
    }

    case FETCH_REPORTS_SUCCESS: {
      const newState = { ...state };

      newState.printsByUser = action.payload.printsByUser;
      newState.printsByCulture = action.payload.printsByCulture;
      newState.downloadsByUser = action.payload.downloadsByUser;
      newState.downloadsByCulture = action.payload.downloadsByCulture;

      return newState;
    }

    case FETCH_REPORTS_ERROR: {
      const newState = { ...state };

      newState.errors = action.payload;

      return newState;
    }

    default:
      return state;
  }
}

export default reportReducer;
