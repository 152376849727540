import React, { FunctionComponent, Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { States, Api } from '@core/types';
import ApprovalRow from './ApprovalRow';
import { adminActionCreators } from '@redux/admin';
import { PassportEnums } from '@tti/passport';
import { usePassportContext } from '@tti/passport';
import { ProjectEnums } from '@core/enums';
import { ProjectPDF } from '@core/types/models';

interface IProps {
  admin?: States.IAdminState;
  fetchPdfs: (params: Api.IFetchPdfsRequest) => void;
}

const PendingApprovals: FunctionComponent<IProps> = ({ admin, fetchPdfs }) => {
  const { passportContext, getClaims } = usePassportContext();
  const [status, setStatus] = useState<ProjectEnums.PrinterApprovalStatus>(ProjectEnums.PrinterApprovalStatus.AwaitingApproval);
  const approvalClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.SentToPrintApprover),
  );
  const reportingClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.ReportingAccess),
  );

  useEffect(() => {
    if (!passportContext.bearerToken || (approvalClaims.length === 0 && reportingClaims.length === 0)) {
      return;
    }

    const cultureCode = approvalClaims.map(x => {
      // example: role.ftg.sent-to-print-approver.en-gb
      // Get the last index of '.'
      const index = x.value.lastIndexOf('.');

      // + 1 to skip the last '.' and return the culture code
      return x.value.substring(index + 1, x.value.length);
    });

    fetchPdfs({
      bearerToken: passportContext.bearerToken,
      cultureCode,
      status,
    });
  }, [status]);

  if (!admin) {
    return null;
  }

  let pdfsToRender: ProjectPDF[] = [];

  switch (status) {
    case ProjectEnums.PrinterApprovalStatus.Approved: {
      pdfsToRender = admin.approvedPdfs.pdfs;
      break;
    }
    case ProjectEnums.PrinterApprovalStatus.Rejected: {
      pdfsToRender = admin.rejectedPdfs.pdfs;
      break;
    }
    case ProjectEnums.PrinterApprovalStatus.AwaitingApproval: {
      pdfsToRender = admin.pendingPdfs.pdfs;
      break;
    }
  }

  return (
    <Fragment>
      <div className="tabs">
        <div className="tabs__container">
          <button
            className={`tab ${status === ProjectEnums.PrinterApprovalStatus.AwaitingApproval ? 'active' : ''}`}
            onClick={() => setStatus(ProjectEnums.PrinterApprovalStatus.AwaitingApproval)}
            disabled={admin.pendingPdfs.isLoading}
          >
            Pending
          </button>
          <button
            className={`tab ${status === ProjectEnums.PrinterApprovalStatus.Approved ? 'active' : ''}`}
            onClick={() => setStatus(ProjectEnums.PrinterApprovalStatus.Approved)}
            disabled={admin.approvedPdfs.isLoading}
          >
            Approved
          </button>
          <button
            className={`tab ${status === ProjectEnums.PrinterApprovalStatus.Rejected ? 'active' : ''}`}
            onClick={() => setStatus(ProjectEnums.PrinterApprovalStatus.Rejected)}
            disabled={admin.rejectedPdfs.isLoading}
          >
            Rejected
          </button>
        </div>
        <div className="tabs__create"></div>
      </div>
      <div className="projects">
        {pdfsToRender.map(x => (
          <ApprovalRow key={x.projectPDFID} project={x} status={status} />
        ))}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  admin: state.admin,
});

const mapDispatchToProps = {
  fetchPdfs: (params: Api.IFetchPdfsRequest) => adminActionCreators.fetchPdfs(params),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingApprovals);
