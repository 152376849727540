import React, { FunctionComponent, useState, Fragment } from 'react';
import { States, Api } from '@core/types';
import { connect } from 'react-redux';
import { usePassportContext } from '@tti/passport';
import { PassportEnums } from '@tti/passport';
import ProjectDetailFooter from './ProjectDetailFooter';
import { productActionCreators } from '@redux/products';
import useConstructor from '@hooks/useConstructor';
import useDestructor from '@hooks/useDestructor';
import { projectActionCreators } from '@redux/projects';
import { FullScreenLoader } from '@components/loaders';
import ProjectDetailTable from './ProjectDetailTable';
import { ProductService } from '@core/services';
import ProjectDetailTableControls from './ProjectDetailTableControls';
import { IndicationMessage } from '@components/shared';
import { Product, ProjectProduct } from '@core/types/models';
import { areEqual } from '@core/utils';
import { ObjectFlags } from 'typescript';
interface IProps {
  product?: States.IProductState;
  project?: States.IProjectState;
  fetchProjectProducts: (params: Api.IFetchProductsRequest) => void;
  clearProject: () => void;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
}

const ProjectDetail: FunctionComponent<IProps> = ({ project, product, fetchProjectProducts, clearProject, updateProject }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const [selectedIds, setSelectedIds] = useState<Record<number, number[] | undefined>>({});

  // Request the products on load
  useConstructor(() => {
    if (
      !passportContext.bearerToken ||
      !cultureClaim ||
      !project ||
      !project.currentProject ||
      !product ||
      product.currentCategories.length > 0
    ) {
      return;
    }

    const variantAgilityIDs = project.currentProject.products.map(x => x.productID);

    fetchProjectProducts({
      bearerToken: passportContext.bearerToken,
      cultureCode: 'en-TT',
      variantAgilityIDs,
    });
  });

  // Clear the current Project and the current Project Categories on leave
  useDestructor(() => {
    clearProject();
  });

  // Null checks
  if (!project || project.currentProject === null || !product) {
    return null;
  }

  // show the loader on first load (when currentCategories is 0)
  if (product.isLoadingCurrentCategories && product.currentCategories.length === 0) {
    return <FullScreenLoader message="Loading Project.." />;
  }

  // get all the products from the current categories
  const allProducts = ProductService.flatten(product.currentCategories);

  const setAll = (set: boolean) => {
    if (!set) {
      setSelectedIds({});
      return;
    }

    const newState: Record<number, number[] | undefined> = {};

    for (const category of product.currentCategories.flatMap(x => x.childCategories)) {
      newState[category.categoryAgilityID] = category.childProducts.map(x => x.variantAgilityID);
    }

    setSelectedIds(newState);
  };

  const handleChange = (data: Record<number, number[]>) => {
    const newState = { ...selectedIds };

    for (let index = 0; index < Object.keys(data).length; index++) {
      const categoryAgilityID = parseInt(Object.keys(data)[index], 10);
      const agilityIDs = data[categoryAgilityID];

      let category = selectedIds[categoryAgilityID];


      if (category === undefined) {
        category = [...agilityIDs];
      } else if (areEqual(category, agilityIDs)) {
        category = undefined;
      } else if (agilityIDs.length > 1) {
        category = [...agilityIDs];
      } else {
        const agilityID = agilityIDs[0];

        const existingIndex = category.indexOf(agilityID);

        if (existingIndex === -1) {
          category.push(agilityID);
        } else {
          category.splice(existingIndex, 1);
        }
      }

      if (category === undefined) {
        delete newState[categoryAgilityID];
      } else {
        newState[categoryAgilityID] = category;
      }
    }

    setSelectedIds(newState);
  };

  const handleRemove = () => {
    if (!passportContext.bearerToken || !cultureClaim || !project.currentProject) {
      return;
    }

    const allSelectedIds: number[] = [];

    for (const key of Object.keys(selectedIds)) {
      const categoryAgilityID = parseInt(key, 10);
      const agilityIds = selectedIds[categoryAgilityID];

      if (agilityIds) {
        allSelectedIds.push(...agilityIds);
      }
    }

    const idsToKeep = allProducts
      .filter(x => allSelectedIds.indexOf(x.variantAgilityID) === -1)
      .map(x => ({
        productID: x.variantAgilityID,
        quantity: 1,
      }));

    updateProject({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      projectGUID: project.currentProject.projectGUID,
      projectProducts: idsToKeep,
      refetchProject: true,
    });

    setAll(false);
  };

  const allSelected = (): boolean => {
    let toReturn = true;

    const categoryAgilityIds = product.currentCategories.flatMap(x => x.childCategories).map(x => x.categoryAgilityID);

    for (const categoryAgilityId of categoryAgilityIds) {
      const agilityIDs = selectedIds[categoryAgilityId];

      if (!agilityIDs || agilityIDs.length === 0) {
        toReturn = false;
        continue;
      }

      const currentCategory = product.currentCategories.flatMap(x => x.childCategories).find(x => x.categoryAgilityID === categoryAgilityId)?.childProducts.map(x => x.variantAgilityID) || null;
      if (!areEqual(agilityIDs, currentCategory)) {
        toReturn = false;
      }
    }

    return toReturn;
  }

  const updateQuantityOfSelected = (increment: boolean) => {
    if (!passportContext.bearerToken || !cultureClaim || !project.currentProject) {
      return;
    }

    const allSelectedIds: number[] = [];

    for (const key of Object.keys(selectedIds)) {
      const categoryAgilityID = parseInt(key, 10);
      const agilityIds = selectedIds[categoryAgilityID];

      if (agilityIds) {
        allSelectedIds.push(...agilityIds);
      }
    }

    const newProducts = [...project.currentProject.products];
    
    for (let index = newProducts.length - 1; index >= 0; index--) {
      const product = newProducts[index];
      
      if (allSelectedIds.indexOf(product.productID) === -1) {
        continue;
      }

      if (increment) {
        product.quantity += 1;
      } else {
        product.quantity -= 1;

        if (product.quantity <= 0) {
          newProducts.splice(index, 1);
        }
      }
    }

    updateProject({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      projectGUID: project.currentProject.projectGUID,
      projectProducts: newProducts,
      refetchProject: true,
    });
  }

  return (
    <Fragment>
        <div className="selected-products__title">Fact Tag Generator</div>
        
        {project.currentProject.products.length === 0 ? (
          <IndicationMessage>
            <Fragment>
              <p>You haven't added any products yet.</p>
              <p>Use the menu to search for products or import an existing list of article numbers.</p>
            </Fragment>
          </IndicationMessage>
        ) : (
          <Fragment>
            <div className="selected-products">
              <ProjectDetailTableControls project={project} allSelected={allSelected()} setAll={setAll} onRemove={handleRemove} onQuantityChanged={(increment) => updateQuantityOfSelected(increment)} anySelected={Object.keys(selectedIds).length > 0} />

              <ProjectDetailTable
                project={project}
                categories={product.currentCategories}
                selectedIds={selectedIds}
                onChange={handleChange}
                updateProject={updateProject}
              />
            </div>
          <ProjectDetailFooter />
          </Fragment>
        )}
        
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  product: state.product,
});

const mapDispatchToProps = {
  updateProject: (params: Api.IUpdateProjectRequest) => projectActionCreators.updateProject(params),
  fetchProjectProducts: (params: Api.IFetchProductsRequest) => productActionCreators.fetchProjectProducts(params),
  clearProject: () => projectActionCreators.clearProject(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDetail);
