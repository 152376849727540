import React, { FunctionComponent } from 'react';
import { MdArrowBack, MdFirstPage, MdArrowForward, MdLastPage } from 'react-icons/md';

export type TablePaginationProps = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  pageOptions: number[];
  pageIndex: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
};

const TablePagination: FunctionComponent<TablePaginationProps> = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  pageIndex,
}) => {
  return (
    <>
      <div className="table__pagination">
        <div className="table__pagination--arrows">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="table__pagination--icon">
            <MdFirstPage title="Go to the first page" />
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className="table__pagination--icon">
            <MdArrowBack title="Go to the previous page" />
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage} className="table__pagination--icon">
            <MdArrowForward title="Go to the next page" />
          </button>
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="table__pagination--icon">
            <MdLastPage title="Go to the last page" />
          </button>
        </div>
        <span className="table__pagination--page">
          <p>Page</p>
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
      </div>
    </>
  );
};

export default TablePagination;
