import React, { Fragment, FunctionComponent, useState } from 'react';
import { Dropdown } from '@components/shared';
import { Loader } from '@components/loaders';
import { States } from '@core/types';
import { ProjectEnums } from '@core/enums';
import classNames from 'classnames';
import { t } from '@components/translations';

interface IProps {
  app: States.IAppState;
  project: States.IProjectState;
  onSelectedSettings: (
    cultureCode: string,
    size: ProjectEnums.PDFSize,
    type: ProjectEnums.PDFType,
    headerLayout: ProjectEnums.PDFHeaderLayout,
    isPrint: boolean,
    printArrangement: ProjectEnums.PrintArrangement,
  ) => void;
  onSubmit: () => void;
  onDownload: () => void;
  setOrderDetails: (formData: Record<string, string> | null) => void;
}

const PdfDownloadSettings: FunctionComponent<IProps> = ({ app, project, onSelectedSettings, onDownload }) => {
  const [cultureCode, setCultureCode] = useState<string>('');
  const [pdfSize, setPdfSize] = useState<ProjectEnums.PDFSize>(ProjectEnums.PDFSize.A4Multiple);
  const [printArrangement, setPrintArrangement] = useState<ProjectEnums.PrintArrangement>(ProjectEnums.PrintArrangement.Product);

  const handleSubmitInternal = () => {
    if (cultureCode === '') {
      return;
    }

    onSelectedSettings(cultureCode, pdfSize, ProjectEnums.PDFType.Download, ProjectEnums.PDFHeaderLayout.Normal, false, printArrangement);
  };

  const renderGenerate = () => {
    const buttonStyles = classNames({
      btn: true,
      'btn--disabled': cultureCode === '',
    });

    const dropdownCultures = app.cultures
      .map(x => ({
        key: x.cultureCode,
        value: x.cultureName,
      }))
      .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

    return (
      <Fragment>
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Choose Your Language</h2>
            <p className="align--center">
              Select which language you’d like your fact tags to be generated in and click download to generate your PDF.
            </p>
            <Dropdown
              placeholder="Select Language..."
              onChange={(value: string) => setCultureCode(value)}
              value={cultureCode}
              values={dropdownCultures}
            />

            <Dropdown
              includeSelectedItem={true}
              onChange={(value: string) => setPdfSize(parseInt(value, 10) as ProjectEnums.PDFSize)}
              value={pdfSize.toString()}
              values={[
                {
                  key: ProjectEnums.PDFSize.A4Multiple.toString(),
                  value: 'Standard Product Card (4 x <strong>14.9 x 6.9</strong>)',
                },
                {
                  key: ProjectEnums.PDFSize.A4.toString(),
                  value: 'A4 (1 x <strong>21.0 x 29.7cm</strong>)',
                },
                {
                  key: ProjectEnums.PDFSize.A5.toString(),
                  value: 'A5 (1 x <strong>21.0 x 14.8cm</strong>)',
                },
                {
                  key: ProjectEnums.PDFSize.A6.toString(),
                  value: 'A6 (1 x <strong>10.5 x 14.8cm</strong>)',
                },
                {
                  key: ProjectEnums.PDFSize.A7.toString(),
                  value: 'A7 (1 x <strong>10.5 x 7.4cm</strong>)',
                },
              ]}
            />

            <Dropdown
              includeSelectedItem={true}
              onChange={(value: string) => setPrintArrangement(parseInt(value, 10) as ProjectEnums.PrintArrangement)}
              value={printArrangement.toString()}
              values={[
                {
                  key: ProjectEnums.PrintArrangement.Product.toString(),
                  value: 'Stacked Print Arrangement (Ordered by Product)',
                },
                {
                  key: ProjectEnums.PrintArrangement.Range.toString(),
                  value: 'Range Print Arrangement (Alternate Products)',
                },
              ]}
            />
          </div>
        </div>
        <button className={buttonStyles} onClick={handleSubmitInternal} type="button">
          Create PDF
        </button>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {project.downloadPDF.projectPdf === null && !project.downloadPDF.modal.isGenerating && renderGenerate()}

      {project.downloadPDF.modal.isGenerating && (
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Generating PDF</h2>
            <p className="align--center" dangerouslySetInnerHTML={{ __html: t('ftg.generatepdf.download') }}></p>
            <Loader />
          </div>
        </div>
      )}

      {project.downloadPDF.projectPdf && !project.downloadPDF.modal.isGenerating && (
        <Fragment>
          <div className="form__inner">
            <div className="form__fields">
              <h2 className="align--center">Download your PDF</h2>
              <p className="align--center">Click below to download your PDF</p>
            </div>
          </div>
          <button className="btn" onClick={onDownload}>
            Download
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PdfDownloadSettings;
