import { States } from '@core/types';
import {
  ProductActionTypes,
  FETCH_NAVIGATION,
  FETCH_NAVIGATION_SUCCESS,
  FETCH_NAVIGATION_ERROR,
  FETCH_PROJECT_PRODUCTS,
  FETCH_PROJECT_PRODUCTS_ERROR,
  FETCH_PROJECT_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_SUCCESS,
  IMPORT_BY_ARTICLE_NUMBER,
  IMPORT_BY_ARTICLE_NUMBER_SUCCESS,
  IMPORT_BY_ARTICLE_NUMBER_ERROR,
} from './actions';
import { CLEAR_PROJECT } from '../shared';
import { ProductService } from '@core/services';

const initialState: States.IProductState = {
  isLoadingNavigation: false,
  errors: [],
  powerTools: null,
  gardenTools: null,
  batteriesChargers: null,
  accessories: null,
  handTools: null,
  onePlus: null,
  onePlusHP: null,
  maxPower: null,
  _72v: null,
  lithiumUSB: null,
  link: null,

  isLoadingCurrentCategories: false,
  currentCategories: [],

  isLoadingSearchResults: false,
  searchResults: [],

  isLoadingImportResults: false,
  importResults: [],
  importErrors: [],
};

function productReducer(state: States.IProductState = initialState, action: ProductActionTypes): States.IProductState {
  switch (action.type) {
    case FETCH_NAVIGATION: {
      const newState = { ...state };

      newState.isLoadingNavigation = true;

      return newState;
    }

    case FETCH_NAVIGATION_SUCCESS: {
      const newState = { ...state };

      newState.isLoadingNavigation = false;

      newState.powerTools = action.payload.powerTools;
      newState.accessories = action.payload.accessories;
      newState.gardenTools = action.payload.gardenTools;
      newState.batteriesChargers = action.payload.batteriesChargers;
      newState.handTools = action.payload.handTools;
      newState.onePlus = action.payload.onePlus;
      newState.onePlusHP = action.payload.onePlusHP;
      newState.maxPower = action.payload.maxPower;
      newState._72v = action.payload._72v;
      newState.lithiumUSB = action.payload.lithiumUSB;
      newState.link = action.payload.link;

      return newState;
    }

    case FETCH_PROJECT_PRODUCTS: {
      const newState = { ...state };

      newState.isLoadingCurrentCategories = true;

      return newState;
    }

    case IMPORT_BY_ARTICLE_NUMBER_ERROR:
    case SEARCH_PRODUCTS_ERROR:
    case FETCH_NAVIGATION_ERROR:
    case FETCH_PROJECT_PRODUCTS_ERROR: {
      const newState = { ...state };

      newState.errors = action.payload;

      return newState;
    }

    case FETCH_PROJECT_PRODUCTS_SUCCESS: {
      const newState = { ...state };

      newState.isLoadingCurrentCategories = false;
      newState.currentCategories = action.payload.categories;
      newState.importResults = [];
      newState.importErrors = [];

      return newState;
    }

    case CLEAR_PROJECT: {
      const newState = { ...state };

      newState.currentCategories = [];

      return newState;
    }

    case SEARCH_PRODUCTS: {
      const newState = { ...state };

      newState.isLoadingSearchResults = true;

      return newState;
    }

    case SEARCH_PRODUCTS_SUCCESS: {
      const newState = { ...state };

      newState.searchResults = ProductService.flatten(action.payload.categories);

      return newState;
    }

    case IMPORT_BY_ARTICLE_NUMBER: {
      const newState = { ...state };

      newState.isLoadingImportResults = true;

      return newState;
    }

    case IMPORT_BY_ARTICLE_NUMBER_SUCCESS: {
      const newState = { ...state };

      // Update the state
      newState.importResults = action.payload.importResults;

      // update the import errors
      newState.importErrors = action.payload.importErrors;

      return newState;
    }

    default:
      return state;
  }
}

export default productReducer;
