import React, { Fragment, FunctionComponent } from 'react';
import { Navigation, ProductSelect, ProductImport, ProductSearch } from '@components/navigation';
import { ProjectDetail } from '@components/projects';
import { AppEnums } from '@core/enums';
import { States, Api, Models } from '@core/types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useConstructor from '@hooks/useConstructor';
import { appActionCreators } from '@redux/app';
import { projectActionCreators } from '@redux/projects';
import { usePassportContext } from '@tti/passport';
import { Logo } from '@components/icons';

interface IProps {
  project?: States.IProjectState;
  product?: States.IProductState;
  fetchProject: (params: Api.IFetchProjectRequest) => void;
  fetchProjectPdf: (params: Api.IFetchProjectPdfRequest) => void;
  setNavigation: (navigation: string) => void;
}

const Project: FunctionComponent<IProps> = ({ project, product, fetchProject, fetchProjectPdf, setNavigation }) => {
  const { passportContext } = usePassportContext();
  const { projectGUID, projectPDFID } = useParams<{ projectGUID: string; projectPDFID: string }>();

  useConstructor(() => {
    fetchProject({
      bearerToken: passportContext.bearerToken || '',
      projectGUID: projectGUID || '',
    });

    if (projectPDFID) {
      fetchProjectPdf({
        bearerToken: passportContext.bearerToken || '',
        projectPDFID: parseInt(projectPDFID, 10),
      });
    }
  });

  const renderCategory = (category: Models.Category | null, navigationID: AppEnums.Navigation) => {
    if (category === null) {
      return null;
    }

    return <ProductSelect category={category} navigationID={navigationID} />;
  };

  const handleClick = () => {
    setNavigation(AppEnums.Navigation.None);
  };

  return (
    <Fragment>
      <Navigation />
      <main onClick={handleClick}>
        <Logo className="app__bg" />
        {product && projectGUID && project && project.currentProject && (
          <Fragment>
            <div className="site-wide">
              <ProjectDetail />
            </div>
            {renderCategory(product.powerTools, AppEnums.Navigation.PowerTools)}
            {renderCategory(product.gardenTools, AppEnums.Navigation.GardenTools)}
            {renderCategory(product.batteriesChargers, AppEnums.Navigation.BatteriesChargers)}
            {renderCategory(product.accessories, AppEnums.Navigation.Accessories)}
            {renderCategory(product.handTools, AppEnums.Navigation.HandTools)}
            {renderCategory(product.onePlus, AppEnums.Navigation.OnePlus)}
            {renderCategory(product.onePlusHP, AppEnums.Navigation.OnePlusHP)}
            {renderCategory(product.maxPower, AppEnums.Navigation.MaxPower)}
            {renderCategory(product._72v, AppEnums.Navigation._72v)}
            {renderCategory(product.lithiumUSB, AppEnums.Navigation.LithiumUSB)}
            {renderCategory(product.link, AppEnums.Navigation.Link)}
            <ProductImport />
            <ProductSearch />
          </Fragment>
        )}
      </main>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  product: state.product,
});

const mapDispatchToProps = {
  fetchProject: (params: Api.IFetchProjectRequest) => projectActionCreators.fetchProject(params),
  fetchProjectPdf: (params: Api.IFetchProjectPdfRequest) => projectActionCreators.fetchProjectPdf(params),
  setNavigation: (navigation: string) => appActionCreators.setActiveNav(navigation),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Project);
