import querystring from 'querystring';
import { Api } from '@core/types';
import { SessionStorage } from '@core/storage';

class ApiService {
  async rawRequest<T>(requestParams: Api.IRequest): Promise<T> {
    // Cast to an unknown to remove from our current type <T> (hacky ik, its to work with legacy apis)
    const response = (await this.request<T>(requestParams)) as unknown;

    return response as T;
  }

  async request<T>(request: Api.IRequest): Promise<Api.IBaseResponse<T>> {
    if (request.cacheKey) {
      const cachedValue = SessionStorage.get<Api.IBaseResponse<T>>(request.cacheKey);
      if (cachedValue) {
        return cachedValue as Api.IBaseResponse<T>;
      }
    }
    // Get the url
    const url = this.getUrl(request);

    // Headers
    const headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    });

    if (request.bearerToken) {
      headers.append('authorization', `Bearer ${request.bearerToken}`);
    }

    // construct a request
    const requestOptions: RequestInit = {
      method: request.method,
      headers,
      mode: 'cors',
      cache: 'default',
      body: this.getBody(request),
    };

    // await the response
    const response = await fetch(url, requestOptions);

    const data = (await response.json()) as Api.IBaseResponse<T>;

    if (request.cacheKey) {
      SessionStorage.set(request.cacheKey, data);
    }

    return data;
  }

  getUrl(request: Api.IRequest) {
    const controller = request.controller ? request.controller : 'packout';
    const baseUrl = request.baseUrl || process.env.REACT_APP_API_ENDPOINT;

    let url = `${baseUrl}/api/${controller}/${request.slug}`;

    if ((request.method === Api.Method.GET || request.method === Api.Method.DELETE) && request.params) {
      url += '?';
      url += querystring.stringify({ ...request.params });
    }

    return url;
  }

  getBody(request: Api.IRequest) {
    if (request.method === Api.Method.POST || request.method === Api.Method.DELETE || request.method === Api.Method.PUT) {
      return JSON.stringify(request.params);
    }

    return null;
  }
}

export default new ApiService();
