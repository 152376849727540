import { PrinterApprovalStatus } from '@core/enums/project';
import { States } from '@core/types';
import {
  AdminActionTypes,
  FETCH_PENDING_APPROVALS,
  FETCH_PENDING_APPROVALS_SUCCESS,
  FETCH_PENDING_APPROVALS_ERROR,
  PRINTER_APPROVE_REJECT,
  PRINTER_APPROVE_REJECT_SUCCESS,
  PRINTER_APPROVE_REJECT_ERROR,
} from './actions';

const initialState: States.IAdminState = {
  isApprovingOrRejecting: false,
  errors: [],
  approvedPdfs: {
    isLoading: false,
    pdfs: [],
  },
  pendingPdfs: {
    isLoading: false,
    pdfs: [],
  },
  rejectedPdfs: {
    isLoading: false,
    pdfs: [],
  },
};

function adminReducer(state: States.IAdminState = initialState, action: AdminActionTypes): States.IAdminState {
  switch (action.type) {
    case FETCH_PENDING_APPROVALS: {
      const newState = { ...state };

      switch (action.payload) {
        case PrinterApprovalStatus.AwaitingApproval: {
          newState.pendingPdfs.isLoading = true;
          break;
        }
        case PrinterApprovalStatus.Approved: {
          newState.approvedPdfs.isLoading = true;
          break;
        }
        case PrinterApprovalStatus.Rejected: {
          newState.rejectedPdfs.isLoading = true;
          break;
        }
      }

      return newState;
    }

    case FETCH_PENDING_APPROVALS_SUCCESS: {
      const newState = { ...state };

      switch (action.payload.status) {
        case PrinterApprovalStatus.AwaitingApproval: {
          newState.pendingPdfs.pdfs = action.payload.projectPDFs;
          newState.pendingPdfs.isLoading = false;
          break;
        }
        case PrinterApprovalStatus.Approved: {
          newState.approvedPdfs.pdfs = action.payload.projectPDFs;
          newState.approvedPdfs.isLoading = false;
          break;
        }
        case PrinterApprovalStatus.Rejected: {
          newState.rejectedPdfs.pdfs = action.payload.projectPDFs;
          newState.rejectedPdfs.isLoading = false;
          break;
        }
      }

      return newState;
    }

    case FETCH_PENDING_APPROVALS_ERROR: {
      const newState = { ...state };

      newState.errors = action.payload;

      return newState;
    }

    case PRINTER_APPROVE_REJECT: {
      const newState = { ...state };

      newState.isApprovingOrRejecting = true;

      return newState;
    }

    case PRINTER_APPROVE_REJECT_ERROR:
    case PRINTER_APPROVE_REJECT_SUCCESS: {
      const newState = { ...state };

      newState.isApprovingOrRejecting = false;

      return newState;
    }

    default:
      return state;
  }
}

export default adminReducer;
