import React from 'react';
import { usePassportContext, LoginRouter } from '@tti/passport';
import AppRouter from './AppRouter';
import { FullScreenLoader } from '@components/loaders';
import { Routes } from './Routes';

/**
 * If the cookie exists
 * we don't want to authenticate
 * we go straight to "landing"
 * 401 responses means the cookie is invalid, go to authentication
 */
const Router = () => {
  const { passportContext } = usePassportContext();

  // Check if we have a bearer token
  if (passportContext.bearerToken && passportContext.bearerToken.length > 0) {
    // Check if the user is still being validated
    if (passportContext.isValidating) {
      return <FullScreenLoader message="Validating your user session.." />;
    }

    // Else we show them the App
    return <AppRouter />;
  }

  // We show them the default passport login router
  return <LoginRouter successPath={Routes.home.path} />;
};

export default Router;
