import React, { FunctionComponent, useState, Fragment } from 'react';
import classNames from 'classnames';
import { AppEnums } from '@core/enums';
import { States, Api, Models } from '@core/types';
import { connect } from 'react-redux';
import { productActionCreators } from '@redux/products';
import { usePassportContext } from '@tti/passport';
import { PassportEnums } from '@tti/passport';
import { Modal } from '@components/shared';
import { appActionCreators } from '@redux/app';
import { projectActionCreators } from '@redux/projects';

const pattern = /^([0-9]+)$/gm;

interface IProps {
  app?: States.IAppState;
  product?: States.IProductState;
  project?: States.IProjectState;
  importByArticleNumber: (params: Api.IFetchProductsRequest) => void;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
  setActiveNav: (navigation: string) => void;
}

const ProductImport: FunctionComponent<IProps> = ({ app, product, project, importByArticleNumber, updateProject, setActiveNav }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const [articleNumbers, setArticleNumbers] = useState<string[]>([]);
  const [nonArticleNumbers, setNonArticleNumbers] = useState<string[]>([]);
  const [textArea, setTextArea] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [showModal, setShowModal] = useState(false);

  if (!app || !product || !passportContext.bearerToken || !cultureClaim) {
    return null;
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    setTextArea(value);

    const matchingLines = value.split('\n').filter(x => x.match(pattern));
    setArticleNumbers(matchingLines);
    setNonArticleNumbers(value.split('\n').filter(x => !x.match(pattern)));
    setDisableButton(matchingLines.length === 0);
  };

  const handleSubmit = () => {
    if (!passportContext.bearerToken || !cultureClaim || !project || !project.currentProject || articleNumbers.length === 0) {
      return null;
    }

    // open the modal
    setShowModal(true);

    importByArticleNumber({
      bearerToken: passportContext.bearerToken,
      cultureCode: 'en-TT',
      articleNumbers,
    });

    // close the navigation
    setActiveNav(AppEnums.Navigation.None);
    setDisableButton(true);
    setArticleNumbers([]);
    setTextArea('');
  };

  const handleAddProducts = () => {
    if (!project || !project.currentProject || !passportContext.bearerToken || !cultureClaim || product.importResults.length === 0) {
      return;
    }

    const agilityIDs = [...product.importResults.map(x => x.variantAgilityID)];
    const projectGUID = project.currentProject.projectGUID;

    const projectProducts: Models.ProjectProduct[] = [];

    // Add the existing products
    for (const projectProduct of project.currentProject.products) {
      projectProducts.push({
        productID: projectProduct.productID,
        quantity: projectProduct.quantity,
      });
    }

    // Add the new products
    for (const agilityID of agilityIDs) {
      projectProducts.push({
        productID: agilityID,
        quantity: 1,
      });
    }

    // Add all the products
    updateProject({
      projectGUID,
      projectProducts,
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      refetchProject: true,
    });

    // hide the modal
    setShowModal(false);

    // reset the article numbers
    setArticleNumbers([]);

    // reset the input
    setTextArea('');

    // close the navigation
    setActiveNav(AppEnums.Navigation.None);
  };

  const productImportClassNames = classNames({
    'product-import': true,
    'is-active': app.activeNavigation === AppEnums.Navigation.Import,
  });

  const buttonClassNames = classNames({
    btn: true,
    'btn--disabled': disableButton,
  });

  const importButtonClassNames = classNames({
    btn: true,
    'btn--disabled': product.importResults.length === 0,
  });

  return (
    <Fragment>
      <Modal
        onClose={() => setShowModal(false)}
        visible={showModal && product.importErrors.length === 0 && product.importResults.length > 0}
        showClose={true}
      >
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Import</h2>
            <p>Do you want to import the following products:</p>
            <ul className="product-import__results">
              {product.importResults.map(x => (
                <li key={x.variantAgilityID}>{x.variantName}</li>
              ))}
            </ul>
          </div>
        </div>
        <button className="btn" onClick={handleAddProducts}>
          Import Products
        </button>
      </Modal>
      <Modal onClose={() => setShowModal(false)} visible={showModal && product.importErrors.length > 0} showClose={true}>
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Whoops</h2>
            <p>The following article numbers couldn't be found ({product.importErrors.length + nonArticleNumbers.length}):</p>
            <ul className="product-import__results">
              {product.importErrors.map(x => (
                <li key={x}>{x}</li>
              ))}
              {nonArticleNumbers.map(x => (
                <li key={x}>{x}</li>
              ))}
            </ul>
            {product.importResults.length > 0 && (
              <Fragment>
                <p>Would you like to import the rest anyway? ({product.importResults.length})</p>
                <ul className="product-import__results">
                  {product.importResults.map(x => (
                    <li key={x.variantAgilityID}>{x.variantName}</li>
                  ))}
                </ul>
              </Fragment>
            )}
          </div>
        </div>
        <button className={importButtonClassNames} onClick={handleAddProducts}>
          Import Products
        </button>
      </Modal>
      <div className={productImportClassNames} onClick={e => e.stopPropagation()}>
        <div className="product-import__container">
          <div className="product-import__title">Paste in your article numbers</div>
          <div className="product-import__import-container">
            <textarea value={textArea} onChange={handleOnChange} id="txtImport" className="product-import__import"></textarea>
          </div>
        </div>
        <div className="product-import__button" onClick={handleSubmit}>
          <button className={buttonClassNames}>Add Products</button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  product: state.product,
  project: state.project,
});

const mapDispatchToProps = {
  importByArticleNumber: (params: Api.IFetchProductsRequest) => productActionCreators.importByArticleNumber(params),
  setActiveNav: (navigation: string) => appActionCreators.setActiveNav(navigation),
  updateProject: (params: Api.IUpdateProjectRequest) => projectActionCreators.updateProject(params),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductImport);
