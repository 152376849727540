interface IRoute {
  path: string;
  slug?: string;
}

interface IRoutes {
  styleguide: IRoute;
  home: IRoute;
  project: IRoute;
  admin: IRoute;
  reports: IRoute;
}

const Routes: IRoutes = {
  styleguide: {
    path: '/styleguide/',
  },
  home: {
    path: '/',
  },
  project: {
    path: '/project/:projectGUID/:projectPDFID?',
    slug: '/project/',
  },
  admin: {
    path: '/admin/',
  },
  reports: {
    path: '/reports',
  },
};

export { Routes };
