import {
  AppActionTypes,
  SET_NAVIGATION,
  FETCH_CULTURES,
  FETCH_CULTURES_SUCCESS,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_INVOICE_COUNTRIES_SUCCESS,
} from './actions';
import { Api, Models } from '@core/types';
import { ApiService } from '@core/services';
import { Dispatch } from 'redux';

export function setActiveNav(navigation: string): AppActionTypes {
  return {
    type: SET_NAVIGATION,
    payload: navigation,
  };
}

export const fetchCultures = () => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_CULTURES,
  });

  const response = await ApiService.request<Models.Culture[]>({
    method: Api.Method.GET,
    controller: 'culture',
    slug: 'get-cultures',
    cacheKey: 'all-cultures',
  });

  dispatch({
    type: FETCH_CULTURES_SUCCESS,
    payload: response.data,
  });
};

export const fetchCountries = () => async (dispatch: Dispatch) => {
  const response = await ApiService.request<Models.Country[]>({
    method: Api.Method.GET,
    controller: 'culture',
    slug: 'get-countries',
    cacheKey: 'all-countries',
  });

  dispatch({
    type: FETCH_COUNTRIES_SUCCESS,
    payload: response.data,
  });
};

export const fetchInvoiceCountries = () => async (dispatch: Dispatch) => {
  const response = await ApiService.request<Models.Country[]>({
    method: Api.Method.GET,
    controller: 'culture',
    slug: 'get-invoice-countries',
    cacheKey: 'all-invoice-countries',
  });

  dispatch({
    type: FETCH_INVOICE_COUNTRIES_SUCCESS,
    payload: response.data,
  });
};
