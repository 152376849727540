import React, { FunctionComponent } from 'react';

interface IProps {
  className?: string;
}

const Logo: FunctionComponent<IProps> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184 70.109">
    <g clipPath="url(#clip0_10_2)">
      <path
        d="M71.637 14.02L62.637 28.313L54.164 14.061L43.695 14.019L57.41 37.829L57.4 56.045H67.312L67.325 38.045L82.125 14.057L71.637 14.02Z"
        fill="white"
      />
      <path d="M169.979 14.022H160.413V56.086H169.979V14.022Z" fill="white" />
      <path
        d="M99.133 47.806C96.6805 47.806 94.2831 47.0787 92.2439 45.7162C90.2048 44.3537 88.6154 42.4171 87.6769 40.1513C86.7384 37.8855 86.4928 35.3922 86.9713 32.9869C87.4497 30.5815 88.6307 28.372 90.3649 26.6379C92.099 24.9037 94.3085 23.7227 96.7139 23.2443C99.1192 22.7658 101.612 23.0114 103.878 23.9499C106.144 24.8884 108.081 26.4778 109.443 28.5169C110.806 30.5561 111.533 32.9535 111.533 35.406C111.533 38.6947 110.227 41.8487 107.901 44.1741C105.576 46.4996 102.422 47.806 99.133 47.806V47.806ZM99.133 13.431C94.7871 13.43 90.5384 14.7178 86.9244 17.1315C83.3104 19.5452 80.4933 22.9764 78.8293 26.9911C77.1654 31.0059 76.7294 35.424 77.5764 39.6865C78.4234 43.9491 80.5154 47.8648 83.5879 50.9384C86.6604 54.012 90.5753 56.1055 94.8376 56.9541C99.0999 57.8026 103.518 57.3682 107.533 55.7057C111.549 54.0433 114.981 51.2274 117.396 47.6142C119.811 44.0011 121.1 39.7529 121.101 35.407C121.1 29.5801 118.786 23.992 114.667 19.871C110.547 15.7501 104.96 13.4337 99.133 13.431"
        fill="white"
      />
      <path
        d="M142.895 47.293H134.755V38.893H142.895C144.009 38.893 145.077 39.3355 145.865 40.1231C146.653 40.9108 147.095 41.9791 147.095 43.093C147.095 44.2069 146.653 45.2752 145.865 46.0628C145.077 46.8505 144.009 47.293 142.895 47.293ZM151.933 34.083C153.319 32.7294 154.341 31.0475 154.903 29.1936C155.466 27.3396 155.55 25.3736 155.15 23.478C154.357 19.597 151.294 14.04 143.079 14.007L122.505 14.02L122.488 22.877L142.46 22.904C143.475 22.9203 144.442 23.3348 145.154 24.0582C145.866 24.7815 146.265 25.7557 146.265 26.7705C146.265 27.7853 145.866 28.7595 145.154 29.4828C144.442 30.2061 143.475 30.6207 142.46 30.637H125.17L125.188 56.047H142.54C144.178 56.1708 145.825 56.0634 147.433 55.728C151.941 54.322 156.577 49.792 156.577 43.828C156.617 43.4671 156.637 43.1041 156.635 42.741C156.285 38.618 154.821 35.698 151.935 34.086"
        fill="white"
      />
      <path
        d="M32.888 14.033C35.9849 14.0342 38.9657 15.2125 41.2262 17.3293C43.4868 19.4461 44.8583 22.343 45.0627 25.4332C45.2672 28.5234 44.2893 31.5758 42.3273 33.9719C40.3653 36.3681 37.5658 37.9288 34.496 38.338V38.33L46.303 56.33H34.942L23.489 39.053V56.308H14.022V30.915L32.184 30.921C33.2101 30.921 34.1942 30.5134 34.9198 29.7878C35.6454 29.0622 36.053 28.0781 36.053 27.052C36.053 26.0259 35.6454 25.0418 34.9198 24.3162C34.1942 23.5906 33.2101 23.183 32.184 23.183L9.922 23.172L9.93 14.042H32.619C32.619 14.042 32.809 14.034 32.888 14.034"
        fill="white"
      />
      <path
        d="M173.309 54.354V53.854H173.464C173.714 53.854 173.832 53.93 173.832 54.113C173.832 54.278 173.719 54.358 173.492 54.358L173.309 54.354ZM172.791 55.41H173.309V54.66H173.459L173.813 55.41H174.383L173.964 54.581C174.079 54.5547 174.181 54.4898 174.254 54.3972C174.327 54.3046 174.366 54.1898 174.364 54.072C174.364 53.719 174.091 53.526 173.55 53.526H172.792L172.791 55.41ZM173.516 55.91C173.146 55.9059 172.792 55.757 172.531 55.4954C172.269 55.2337 172.12 54.88 172.116 54.51C172.116 54.3215 172.153 54.1348 172.226 53.9608C172.299 53.7869 172.406 53.6292 172.54 53.497C172.668 53.3683 172.821 53.2666 172.99 53.1979C173.158 53.1292 173.338 53.0949 173.52 53.097C173.799 53.0977 174.071 53.1811 174.302 53.3365C174.534 53.4919 174.714 53.7125 174.82 53.9702C174.926 54.228 174.953 54.5114 174.898 54.7846C174.842 55.0578 174.708 55.3085 174.51 55.505C174.244 55.7644 173.887 55.9097 173.516 55.91V55.91ZM173.516 56.225C173.969 56.2219 174.404 56.0425 174.727 55.725C174.891 55.5672 175.021 55.3774 175.108 55.1674C175.196 54.9573 175.239 54.7315 175.236 54.504C175.239 54.2775 175.196 54.0527 175.111 53.8429C175.025 53.633 174.899 53.4424 174.739 53.2822C174.578 53.1219 174.388 52.9953 174.178 52.9098C173.968 52.8242 173.744 52.7815 173.517 52.784C173.296 52.7817 173.077 52.8233 172.872 52.9066C172.668 52.9899 172.482 53.1131 172.325 53.269C172.16 53.4298 172.029 53.6219 171.94 53.8339C171.85 54.046 171.804 54.2738 171.803 54.504C171.803 54.9535 171.981 55.3848 172.297 55.704C172.455 55.8692 172.644 56.0007 172.854 56.0904C173.064 56.1802 173.29 56.2263 173.518 56.226"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_10_2">
        <rect width="184" height="70.109" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
