const keys: string[] = [
  'ftg.generatepdf.download',
  'ftg.generatepdf.sendtoprinters',
  'Lang_en-EU',
  'Lang_cs-CZ',
  'Lang_da-DK',
  'Lang_de-CH',
  'Lang_de-DE',
  'Lang_en-GB',
  'Lang_es-ES',
  'Lang_fi-FI',
  'Lang_fr-BE',
  'Lang_fr-CH',
  'Lang_fr-FR',
  'Lang_hu-HU',
  'Lang_it-IT',
  'Lang_nl-BE',
  'Lang_nl-NL',
  'Lang_nn-NO',
  'Lang_pl-PL',
  'Lang_ru-RU',
  'Lang_sk-SK',
  'Lang_sv-SE',
  'Lang_lt-lt',
  'Lang_et-EE',
  'Lang_lv-LV',
  'Lang_ar-AE',
  'Lang_en-ZA',
  'Lang_pt-PT',
  'Lang_tr-TR',
  'Lang_ro-RO',
  'lang_en-TT',
  'lang_de-AT',
  'lang_fr-LU',
  'lang_de-LU',
];

export default keys;
