import Translate from './Translate';
import store from '@redux/store';
import { keys } from '@core/translations';
import { States } from '@core/types';

function t(resourceString: string) {
  if (keys.find(x => x.toLowerCase() === resourceString.toLowerCase()) === undefined) {
    console.error(`${resourceString} missing in keys.js`);
    return resourceString;
  }

  const state = store.getState();
  const translation = state.translation as States.ITranslation;

  // Check keys.js to see if we have added the key.
  if (keys.findIndex(x => x.toLowerCase() === resourceString.toLowerCase()) === -1) {
    console.error(`${resourceString} missing in keys.js`);
    return resourceString;
  }

  // Try and lookup the value in the translations
  const lookup = translation.values.find(x => x.key.toLowerCase() === resourceString.toLowerCase());

  // Fall back to the resource string if no value
  if (!lookup) {
    console.error(`Translation missing for ${resourceString}`);
    return resourceString;
  }

  // Return the translated value
  return lookup.value;
}

export { Translate, t };
