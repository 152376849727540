import { States, Api } from '@core/types';
import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  ProjectActionTypes,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  GENERATE_PDF_ERROR,
  GENERATE_PDF,
  GENERATE_PDF_SUCCESS,
  SEND_FOR_APPROVAL,
  SEND_FOR_APPROVAL_ERROR,
  SEND_FOR_APPROVAL_SUCCESS,
  SET_PDF_MODAL,
  CLEAR_PDF,
  SET_ORDER_DETAILS,
  FETCH_PROJECT_PDF_ERROR,
  FETCH_PROJECT_PDF_SUCCESS,
  FETCH_PROJECT_PDF,
  FETCH_USER_PDFS,
  FETCH_USER_PDFS_ERROR,
  FETCH_USER_PDFS_SUCCESS,
  SET_PRODUCT_QUANTITIES,
} from './actions';
import { CLEAR_PROJECT } from '../shared';
import { ProjectEnums } from '@core/enums';
import { FETCH_TRANSLATIONS_ERROR } from '../translations/actions';

const initialState: States.IProjectState = {
  isCreating: false,
  isLoading: false,
  isLoadingProject: false,
  isDeleting: false,
  isAddingProducts: false,
  projects: [],
  currentProject: null,
  printPDF: {
    valid: true,
    projectPdf: null,
    modal: {
      isOpen: false,
      isGenerating: false,
      isSendingForApproval: false,
      orderDetails: null,
    },
  },
  downloadPDF: {
    valid: true,
    projectPdf: null,
    modal: {
      isOpen: false,
      isGenerating: false,
      isSendingForApproval: false,
      orderDetails: null,
    },
  },
  approvedPDFs: {
    isLoading: false,
    pdfs: [],
  },
  rejectedPDFs: {
    isLoading: false,
    pdfs: [],
  },
  errors: [],
};

function projectReducer(state: States.IProjectState = initialState, action: ProjectActionTypes): States.IProjectState {
  switch (action.type) {
    case FETCH_PROJECTS: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case FETCH_PROJECTS_SUCCESS: {
      const newState = { ...state };

      newState.isLoading = false;
      newState.projects = action.payload.projects;

      return newState;
    }

    case FETCH_PROJECT_PDF:
    case FETCH_PROJECT: {
      const newState = { ...state };

      newState.isLoadingProject = true;

      return newState;
    }

    case FETCH_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.currentProject = action.payload.project;
      newState.isLoadingProject = false;

      return newState;
    }

    case GENERATE_PDF_ERROR:
    case UPDATE_PROJECT_ERROR:
    case FETCH_PROJECT_ERROR:
    case DELETE_PROJECT_ERROR:
    case CREATE_PROJECT_ERROR:
    case FETCH_PROJECTS_ERROR:
    case FETCH_PROJECT_PDF_ERROR: {
      const newState = { ...state };

      newState.isLoading = false;
      newState.isLoadingProject = false;
      newState.isDeleting = false;
      newState.errors = action.payload;

      return newState;
    }

    case CREATE_PROJECT: {
      const newState = { ...state };

      newState.isCreating = true;

      return newState;
    }

    case CREATE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isCreating = false;

      return newState;
    }

    case DELETE_PROJECT: {
      const newState = { ...state };

      newState.isDeleting = true;

      return newState;
    }

    case CLEAR_PROJECT: {
      const newState = { ...state };

      newState.currentProject = null;

      return newState;
    }

    case DELETE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isDeleting = false;

      return newState;
    }

    case UPDATE_PROJECT: {
      const newState = { ...state };

      newState.isAddingProducts = true;

      return newState;
    }

    case UPDATE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isAddingProducts = false;

      if (newState.currentProject !== null) {
        newState.currentProject.products = action.payload.projectProducts;
      }

      if (newState.printPDF.modal.isGenerating) {
        newState.printPDF = {
          modal: {
            isGenerating: false,
            isOpen: false,
            isSendingForApproval: false,
            orderDetails: null,
          },
          projectPdf: null,
          valid: false,
        };
      }

      if (newState.downloadPDF.modal.isGenerating) {
        newState.downloadPDF = {
          modal: {
            isGenerating: false,
            isOpen: false,
            isSendingForApproval: false,
            orderDetails: null,
          },
          projectPdf: null,
          valid: false,
        };
      }

      return newState;
    }

    case GENERATE_PDF: {
      const newState = { ...state };

      if (action.payload === ProjectEnums.PDFType.SendToPrinters) {
        newState.printPDF.modal.isGenerating = true;
        newState.downloadPDF.valid = true;
      }

      if (action.payload === ProjectEnums.PDFType.Download) {
        newState.downloadPDF.modal.isGenerating = true;
        newState.downloadPDF.valid = true;
      }

      return newState;
    }

    case GENERATE_PDF_SUCCESS: {
      const newState = { ...state };

      if (action.payload.type === ProjectEnums.PDFType.SendToPrinters) {
        if (newState.printPDF.valid) {
          newState.printPDF.projectPdf = action.payload.response.projectPDF;

          newState.printPDF.modal.orderDetails = null;
          newState.printPDF.modal.isGenerating = false;
        }
      }

      if (action.payload.type === ProjectEnums.PDFType.Download) {
        if (newState.downloadPDF.valid) {
          newState.downloadPDF.projectPdf = action.payload.response.projectPDF;

          newState.downloadPDF.modal.isGenerating = false;
        }
      }

      return newState;
    }

    case SEND_FOR_APPROVAL: {
      const newState = { ...state };

      newState.printPDF.modal.isSendingForApproval = true;

      return newState;
    }

    case SEND_FOR_APPROVAL_SUCCESS: {
      const newState = { ...state };

      newState.printPDF.modal.isSendingForApproval = false;

      return newState;
    }

    case SET_PDF_MODAL: {
      const newState = { ...state };

      if (action.payload.pdfType === ProjectEnums.PDFType.Download) {
        if (action.payload.isGenerating !== undefined) {
          newState.downloadPDF.modal.isGenerating = action.payload.isGenerating;
        }

        if (action.payload.isOpen !== undefined) {
          newState.downloadPDF.modal.isOpen = action.payload.isOpen;
        }

        if (action.payload.isSendingForApproval !== undefined) {
          newState.downloadPDF.modal.isSendingForApproval = action.payload.isSendingForApproval;
        }
      }

      if (action.payload.pdfType === ProjectEnums.PDFType.SendToPrinters) {
        if (action.payload.isGenerating !== undefined) {
          newState.printPDF.modal.isGenerating = action.payload.isGenerating;
        }

        if (action.payload.isOpen !== undefined) {
          newState.printPDF.modal.isOpen = action.payload.isOpen;
        }

        if (action.payload.isSendingForApproval !== undefined) {
          newState.printPDF.modal.isSendingForApproval = action.payload.isSendingForApproval;
        }

        if (action.payload.showConfirmation !== undefined) {
          newState.printPDF.modal.showConfirmation = action.payload.showConfirmation;
        }
      }

      return newState;
    }

    case CLEAR_PDF: {
      const newState = { ...state };

      if (action.payload === ProjectEnums.PDFType.Download) {
        newState.downloadPDF.projectPdf = null;
      }

      if (action.payload === ProjectEnums.PDFType.SendToPrinters) {
        newState.printPDF.projectPdf = null;
      }

      return newState;
    }

    case SET_ORDER_DETAILS: {
      const newState = { ...state };

      newState.printPDF.modal.orderDetails = action.payload;

      return newState;
    }

    case FETCH_PROJECT_PDF_SUCCESS: {
      const newState = { ...state };

      newState.printPDF.projectPdf = action.payload.projectPDF;
      newState.printPDF.modal.isOpen = true;

      return newState;
    }

    case FETCH_USER_PDFS: {
      const newState = { ...state };

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Approved) {
        newState.approvedPDFs.isLoading = true;
      }

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Rejected) {
        newState.rejectedPDFs.isLoading = true;
      }

      return newState;
    }

    case FETCH_USER_PDFS_SUCCESS: {
      const newState = { ...state };

      if (action.payload.status === ProjectEnums.PrinterApprovalStatus.Approved) {
        newState.approvedPDFs.pdfs = [...action.payload.response.projectPDFs];
      }

      if (action.payload.status === ProjectEnums.PrinterApprovalStatus.Rejected) {
        newState.rejectedPDFs.pdfs = [...action.payload.response.projectPDFs];
      }

      return newState;
    }

    case FETCH_USER_PDFS_ERROR: {
      const newState = { ...state };

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Approved) {
        newState.approvedPDFs.isLoading = true;
      }

      if (action.payload === ProjectEnums.PrinterApprovalStatus.Rejected) {
        newState.rejectedPDFs.isLoading = true;
      }

      return newState;
    }

    default:
      return state;
  }
}

export default projectReducer;
