export enum PrinterApprovalStatus {
  NotSent = 0,
  AwaitingApproval = 1,
  Approved = 2,
  Rejected = 3,
  Generating = 4,
}

export enum PDFType {
  Download = 0,
  SendToPrinters = 1,
}

export enum PDFSize {
  A4Multiple = 0,
  A4 = 1,
  A5 = 2,
  A7 = 3,
  Print = 4,
  A6 = 5,
}

export enum PrintArrangement {
  Product = 0,
  Range = 1,
}

export enum PDFHeaderLayout {
  Normal = 0,
  NoLogo = 1,
}
