import React, { FunctionComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from './Routes';
import { Landing, Project, Admin } from '@components/pages';
import { Styleguide } from '@components/styleguide';
import { usePassportContext } from '@tti/passport';
import { States, Api } from '@core/types';
import { connect } from 'react-redux';
import { FullScreenLoader } from '@components/loaders';
import useConstructor from '@hooks/useConstructor';
import { productActionCreators } from '@redux/products';
import { PassportEnums } from '@tti/passport';
import { adminActionCreators } from '@redux/admin';
import { translationActionCreators } from '@redux/translations';
import { appActionCreators } from '@core/redux/app';
import { PrinterApprovalStatus } from '@core/enums/project';
import Reporting from '@components/pages/Reporting';

interface IProps {
  app?: States.IAppState;
  product?: States.IProductState;
  translation?: States.ITranslation;
  fetchNavigation: (bearerToken: string, cultureCode: string) => void;
  fetchPdfs: (params: Api.IFetchPdfsRequest) => void;
  fetchTranslations: (cultureCode: string) => void;
  fetchCultures: () => void;
  fetchCountries: () => void;
  fetchInvoiceCountries: () => void;
}

const AppRouter: FunctionComponent<IProps> = ({
  app,
  product,
  translation,
  fetchNavigation,
  fetchTranslations,
  fetchPdfs,
  fetchCultures,
  fetchCountries,
  fetchInvoiceCountries,
}) => {
  const { passportContext, getClaim, getClaims } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const approvalClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.SentToPrintApprover),
  );
  const reportingClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.ReportingAccess),
  );

  useConstructor(() => {
    if (!cultureClaim || !passportContext.bearerToken) {
      return;
    }

    // Fetch translations
    fetchTranslations(cultureClaim.value);

    // Fetch the navigation
    fetchNavigation(passportContext.bearerToken, 'en-TT');

    // Fetch the cultures
    fetchCultures();

    // Fetch the countries
    fetchCountries();

    // Fetch the invoice countries
    fetchInvoiceCountries();

    // Conditionally fetch the pending approvals
    if (approvalClaims) {
      const cultureCodes = approvalClaims.map(x => {
        // example: role.ftg.sent-to-print-approver.en-gb
        // Get the last index of '.'
        const index = x.value.lastIndexOf('.');

        // + 1 to skip the last '.' and return the culture code
        return x.value.substring(index + 1, x.value.length);
      });
      fetchPdfs({
        bearerToken: passportContext.bearerToken,
        cultureCode: cultureCodes,
        status: PrinterApprovalStatus.AwaitingApproval,
      });
    }
  });

  if (!app || !product || !translation) {
    return null;
  }

  if (app.isLoadingCultures) {
    return <FullScreenLoader message="Loading Cultures..." />;
  }

  if (product.isLoadingNavigation) {
    return <FullScreenLoader message="Loading Products..." />;
  }

  if (translation.isLoading && !product.isLoadingNavigation) {
    return <FullScreenLoader message="Loading Translations..." />;
  }

  return (
    <Switch>
      <Route exact path={Routes.home.path} component={Landing} />
      <Route exact path={Routes.project.path} component={Project} />
      <Route exact path={Routes.styleguide.path} component={Styleguide} />

      {/* Conditionally add the admin route based on the users role so no unauthed users can directly visit /admin/ */}
      {(approvalClaims.length > 0 || reportingClaims.length > 0) && <Route exact path={Routes.admin.path} component={Admin} />}

      {/* Conditionally add the reporting route based on the users role so no unauthed users can directly visit /reports/ */}
      {reportingClaims.length > 0 && <Route exact path={Routes.reports.path} component={Reporting} />}

      <Redirect to={Routes.home.path} />
    </Switch>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  product: state.product,
  translation: state.translation,
});

const mapDispatchToProps = {
  fetchNavigation: (bearerToken: string, cultureCode: string) => productActionCreators.fetchNavigation(bearerToken, cultureCode),
  fetchPdfs: (params: Api.IFetchPdfsRequest) => adminActionCreators.fetchPdfs(params),
  fetchTranslations: (cultureCode: string) => translationActionCreators.fetchTranslations(cultureCode),
  fetchCultures: () => appActionCreators.fetchCultures(),
  fetchCountries: () => appActionCreators.fetchCountries(),
  fetchInvoiceCountries: () => appActionCreators.fetchInvoiceCountries(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppRouter);
