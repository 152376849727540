import React, { FunctionComponent, useState, Dispatch, SetStateAction } from 'react';
import ClassNames from 'classnames';
import { MdKeyboardArrowDown } from 'react-icons/md';

interface SelectedProductsProps {
  setLanguageDropdown: Dispatch<SetStateAction<boolean>>;
}

const SelectedProducts: FunctionComponent<SelectedProductsProps> = ({ setLanguageDropdown }) => {
  const [active, setActive] = useState(false);

  const categoryContainerClassNames = ClassNames({
    'selected-products__category-container': true,
    'is-active': active,
  });

  const categoryClassNames = ClassNames({
    'selected-products__category': true,
    'is-active': active,
  });

  return (
    <div className="site-wide">
      <div className="selected-products">
        <div className="selected-products__controls">
          <div className="selected-products__select-all">
            <input id="selectedAllchkbox1" type="checkbox" className="selected-products__select-all--input" />
            <label htmlFor="selectedAllchkbox1" className="selected-products__select-all--label">
              Select All
            </label>
          </div>
          <button className="selected-products__remove">Remove</button>
        </div>
        <div className="selected-products__container">
          <div className={categoryClassNames} onClick={() => setActive(!active)}>
            Cordless
            <MdKeyboardArrowDown className="selected-products__category--arrow" />
          </div>
          <div className={categoryContainerClassNames}>
            <div className="selected-products__sub-category-container">
              <div className="selected-products__sub-category">Batteries and Chargers</div>
              <div className="selected-products__products">
                <div className="selected-products__product-item">
                  <input id="selectedchkbox1" type="checkbox" className="selected-products__product-item--input" />
                  <label htmlFor="selectedchkbox1" className="selected-products__product-item--label">
                    M18 HB12
                  </label>
                </div>
                <div className="selected-products__product-item">
                  <input id="selectedchkbox2" type="checkbox" className="selected-products__product-item--input" />
                  <label htmlFor="selectedchkbox2" className="selected-products__product-item--label">
                    M18 HB8
                  </label>
                </div>
                <div className="selected-products__product-item">
                  <input id="selectedchkbox3" type="checkbox" className="selected-products__product-item--input" />
                  <label htmlFor="selectedchkbox3" className="selected-products__product-item--label">
                    M18 HB5.5
                  </label>
                </div>
              </div>
            </div>
            <div className="selected-products__sub-category-container">
              <div className="selected-products__sub-category">Drilling and Chipping</div>
              <div className="selected-products__products">
                <div className="selected-products__product-item">
                  <input id="selectedchkbox4" type="checkbox" className="selected-products__product-item--input" />
                  <label htmlFor="selectedchkbox4" className="selected-products__product-item--label">
                    M18 HB12
                  </label>
                </div>
                <div className="selected-products__product-item">
                  <input id="selectedchkbox5" type="checkbox" className="selected-products__product-item--input" />
                  <label htmlFor="selectedchkbox5" className="selected-products__product-item--label">
                    M18 HB8
                  </label>
                </div>
                <div className="selected-products__product-item">
                  <input id="selectedchkbox6" type="checkbox" className="selected-products__product-item--input" />
                  <label htmlFor="selectedchkbox6" className="selected-products__product-item--label">
                    M18 HB5.5
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="selected-products__container">
          <div className={categoryClassNames}>
            Corded
            <MdKeyboardArrowDown className="selected-products__category--arrow" />
          </div>
        </div>
        <div className="selected-products__footer">
          <button className="btn btn--inline selected-products__download" onClick={() => setLanguageDropdown(true)}>
            Download
          </button>
          <button className="btn btn--inline selected-products__print">Send To Printers</button>
        </div>
      </div>
    </div>
  );
};

export default SelectedProducts;
