import React, { FunctionComponent } from 'react';
import { useTable, usePagination, TableOptions, useSortBy } from 'react-table';
import { Api, Models } from '@core/types';
import TablePagination from './TablePagination';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { ApiService } from '@core/services';
import { ReportType } from '@core/types/api';
import { usePassportContext } from '@tti/passport';

export type TableProps = {
  reportType: ReportType;
  dateFrom: string;
  dateTo: string;
  options: TableOptions<Models.PrintsByUser>;
};

const Table: FunctionComponent<TableProps> = ({ options, reportType, dateFrom, dateTo }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    state: { pageIndex },
  } = useTable(
    {
      ...options,
      initialState: {
        pageSize: 15,
      },
    },
    useSortBy,
    usePagination,
  );

  const { passportContext } = usePassportContext();
  
  async function exportToCsv(reportType: ReportType) {
    if (!passportContext.bearerToken) return;

    const url = ApiService.getUrl({
      method: Api.Method.GET,
      bearerToken: passportContext.bearerToken,
      controller: 'reporting',
      slug: 'export',
      params: {
        reportType,
        dateFrom,
        dateTo,
      },
    });

    await window.fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/octet-stream',
        'Accept': 'application/octet-stream',
        'authorization' : `Bearer ${passportContext.bearerToken}`
      })
    }).then((response) => response.blob()).then((blob) => {
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${ReportType[reportType]}.csv`,
      );

      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    });
  }

  return (
    <div className="table__wrapper">
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`HeaderGroup-${index}`}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={`${column.isSorted ? 'table__sorted' : ''}`}>
                  {column.render('Header')}
                  <span className="table__sort-icon">
                    {column.isSorted ? column.isSortedDesc ? <IoIosArrowDown /> : <IoIosArrowUp /> : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr className={!row.values.cultureCode ? 'region' : ''} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="table__footer">
        <TablePagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          pageIndex={pageIndex}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
        />
        {pageCount > 0 && (
          <a className="btn btn--inline btn--small" onClick={() => exportToCsv(reportType)}>
            Export
          </a>
        )}
      </div>
    </div>
  );
};

export default Table;
