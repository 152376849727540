import { ProjectEnums } from '@core/enums';
import { Api, Models } from '@core/types';

export const FETCH_REPORTS = 'REPORTS/FETCH_REPORTS';
export const FETCH_REPORTS_ERROR = 'REPORTS/FETCH_REPORTS_ERROR';
export const FETCH_REPORTS_SUCCESS = 'REPORTS/FETCH_REPORTS_SUCCESS';

export interface IFetchReports {
  type: typeof FETCH_REPORTS;
}

export interface IFetchReportsError {
  type: typeof FETCH_REPORTS_ERROR;
  payload: Api.IApiError[];
}

export interface IFetchReportsSuccess {
  type: typeof FETCH_REPORTS_SUCCESS;
  payload: {
    printsByUser: Models.PrintsByUser[];
    printsByCulture: Models.PrintsByCulture[];
    downloadsByUser: Models.DownloadsByUser[];
    downloadsByCulture: Models.DownloadsByCulture[];
  };
}

export type ReportActionTypes = IFetchReports | IFetchReportsError | IFetchReportsSuccess;
