import React, { FunctionComponent } from 'react';
import { States } from '@core/types';

interface IProps {
  project: States.IProjectState;
  setAll: (set: boolean) => void;
  onRemove: () => void;
  allSelected: boolean;
  onQuantityChanged: (increment: boolean) => void;
  anySelected: boolean;
}

const ProjectDetailTableControls: FunctionComponent<IProps> = ({
  project,
  allSelected,
  setAll,
  onRemove,
  onQuantityChanged,
  anySelected,
}) => {
  if (!project || !project.currentProject || project.currentProject.products.length === 0) {
    return null;
  }

  return (
    <div className="selected-products__controls">
      <div className="selected-products__select-all">
        <input
          id="selectedAllchkbox1"
          type="checkbox"
          className="selected-products__select-all--input"
          onChange={e => setAll(e.currentTarget.checked)}
          checked={allSelected}
        />
        <label htmlFor="selectedAllchkbox1" className="selected-products__select-all--label">
          Select All
        </label>
      </div>
      {anySelected && (
        <button className="selected-products__remove" onClick={onRemove}>
          Remove
        </button>
      )}
      {anySelected && (
        <div className="product-select__product-item--controls">
          <button onClick={() => onQuantityChanged(false)}>-</button>
          <button onClick={() => onQuantityChanged(true)}>+</button>
        </div>
      )}
    </div>
  );
};

export default ProjectDetailTableControls;
