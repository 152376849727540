import React, { FunctionComponent } from 'react';

interface IProps {
  dateFrom: string;
  setDateFrom: (date: string) => void;
  dateTo: string;
  setDateTo: (date: string) => void;
}

const DateFilters: FunctionComponent<IProps> = ({ dateFrom, dateTo, setDateFrom, setDateTo }) => {
  return (
    <div className="table__date-filters">
      <div className="table__date-filters--group">
        <label htmlFor="dateFrom">Date From:</label>
        <input type="date" value={dateFrom} name="dateFrom" id="dateFrom" onChange={e => setDateFrom(e.target.value)} />
      </div>
      <div className="table__date-filters--group">
        <label htmlFor="dateFrom">Date To:</label>
        <input type="date" value={dateTo} name="dateTo" id="dateTo" onChange={e => setDateTo(e.target.value)} />
      </div>
      {dateFrom > dateTo && (
        <p className="table__date-filters--validation">Please ensure the 'Date To' value is later than the 'Date From' value.</p>
      )}
    </div>
  );
};

export default DateFilters;
