import { FETCH_TRANSLATIONS, FETCH_TRANSLATIONS_SUCCESS, FETCH_TRANSLATIONS_ERROR } from './actions';
import { ApiService } from '@core/services';
import { Api } from '@core/types';
import { Dispatch } from 'redux';
import { keys } from '@core/translations';
import { sessionKeys } from '@core/storage';

export const fetchTranslations = (cultureCode: string) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_TRANSLATIONS,
  });

  const params: GetLocalisationsRequest = {
    cultureCode,
    keys,
    fallbackCulture1: 'en-TT',
    fallbackCulture2: 'en-US',
    fallbackCulture3: 'en-GB',
  };

  const response = await ApiService.rawRequest<KeyValuePair<string, string>[]>({
    baseUrl: 'https://www.ryobitools.eu',
    // baseUrl: 'https://ryobi.local',
    method: Api.Method.POST,
    controller: 'localisation',
    slug: 'localisations',
    params,
    cacheKey: `${sessionKeys.translations}|${cultureCode}`,
  });

  dispatch({
    type: FETCH_TRANSLATIONS_SUCCESS,
    payload: response,
  });

  // dispatch({
  //   type: FETCH_TRANSLATIONS_ERROR,
  //   payload: e,
  // });
};

interface GetLocalisationsRequest {
  keys: string[];
  cultureCode: string;
  fallbackCulture1: string;
  fallbackCulture2: string;
  fallbackCulture3: string;
}

declare interface KeyValuePair<T, K> {
  key: T;
  value: K;
}
