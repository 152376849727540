import React, { FunctionComponent, useState } from 'react';
import ClassNames from 'classnames';

interface LanguageSelectorProps {
  active: boolean;
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({ active }) => {
  const [activeDropdown, setActiveDropdown] = useState(false);

  const modalClassNames = ClassNames({
    'form-modal': true,
    'is-active': active,
  });

  return (
    <div className={modalClassNames}>
      <div className="form__overlay"></div>
      <form className="form">
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Choose Your Language</h2>
            <p className="align--center">
              Select which language you’d like your fact tags to be generated in and click download to generate your PDF.
            </p>
            <div className="form__row">
              <div className={`form__control form__dropdown ${activeDropdown ? 'active' : ''}`}>
                <div className="form__dropdown-selected" onClick={() => setActiveDropdown(!activeDropdown)}>
                  Select Language...
                </div>
                <div className="form__dropdown-values">
                  <div className="form__dropdown-value">Test 1</div>
                  <div className="form__dropdown-value">Test 2</div>
                  <div className="form__dropdown-value">Test 3</div>
                  <div className="form__dropdown-value">Test 4</div>
                  <div className="form__dropdown-value">Test 5</div>
                  <div className="form__dropdown-value">Test 6</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn--grey" type="button">
          Download
        </button>
      </form>
    </div>
  );
};

export default LanguageSelector;
