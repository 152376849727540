import React, { Fragment, FunctionComponent } from 'react';
import { Navigation } from '@components/navigation';
import { PendingApprovals } from '@components/approvals';
import { Logo } from '@components/icons';

interface IProps {}

const Admin: FunctionComponent<IProps> = () => (
  <Fragment>
    <Navigation />
    <main>
      <Logo className="app__bg" />
      <div className="site-wide">
        <PendingApprovals />
      </div>
    </main>
  </Fragment>
);

export default Admin;
