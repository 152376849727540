export enum AgilityIDs {
  PowerTools = 224419,
  GardenTools = 224420,
  BatteriesChargers = 224792,
  Accessories = 224422,
  HandTools = 539665,
}

export enum SystemIDs {
  onePlus = 'OnePlus',
  onePlusHP = 'OnePlusHP',
  maxPower = 'MaxPower',
  _72v = '_72v',
  lithiumUSB = 'LithiumUSB',
  link = 'Link',
}
