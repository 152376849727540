import React, { Fragment, FunctionComponent } from 'react';
import { Navigation } from '@components/navigation';
import { Logo } from '@components/icons';
import ReportsTable from '@components/reports/ReportsTable';

interface IProps {}

const Reporting: FunctionComponent<IProps> = () => (
  <Fragment>
    <Navigation />
    <main>
      {/* <Logo className="app__bg" /> */}
      {/* Above keeps lagging the page whenever it's in view.... */}
      <div className="site-wide">
        <ReportsTable />
      </div>
    </main>
  </Fragment>
);

export default Reporting;
