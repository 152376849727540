import React, { FunctionComponent, Fragment } from 'react';
import { usePassportContext } from '@tti/passport';
import { Api, States, Models } from '@core/types';
import { connect } from 'react-redux';
import { projectActionCreators } from '@redux/projects';
import { Modal } from '@components/shared';
import { PassportEnums } from '@tti/passport';
import PdfPrintSettings from './PdfPrintSettings';
import { ProjectEnums } from '@core/enums';
import PdfDownloadSettings from './PdfDownloadSettings';

interface IProps {
  app?: States.IAppState;
  project?: States.IProjectState;
  generatePDF: (params: Api.IGeneratePDFRequest, type: ProjectEnums.PDFType) => void;
  sendForApproval: (params: Api.ISendForApprovalRequest, projectGuid: string, userCultures: string[]) => void;
  setPDFModal: (params: Models.SetPDFModal) => void;
  setOrderDetails: (formData: Record<string, string> | null) => void;
  downloadPDF: (url: string) => void;
  clearPDF: (type: ProjectEnums.PDFType) => void;
}

const ProjectDetailFooter: FunctionComponent<IProps> = ({
  app,
  project,
  generatePDF,
  sendForApproval,
  setPDFModal,
  clearPDF,
  downloadPDF,
  setOrderDetails,
}) => {
  const { passportContext, getClaim, getClaims } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const allCultureClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.SentToPrintApprover),
  );

  const handleShowGeneratePdf = (type: ProjectEnums.PDFType) => {
    setPDFModal({
      isOpen: true,
      pdfType: type,
    });
  };

  const handleCloseGeneratePdf = (type: ProjectEnums.PDFType) => {
    // Reset the PDF modal
    setPDFModal({
      pdfType: type,
      isOpen: false,
      showConfirmation: false,
    });
  };

  const handleGeneratePDF = (
    cultureCode: string,
    size: ProjectEnums.PDFSize,
    type: ProjectEnums.PDFType,
    headerLayout: ProjectEnums.PDFHeaderLayout,
    isPrint: boolean,
    printArrangement: ProjectEnums.PrintArrangement,
  ) => {
    if (!project || project.currentProject === null || !passportContext.bearerToken) {
      return;
    }

    let orderDetails;
    if (project.printPDF.modal.orderDetails !== null) {
      orderDetails = {
        orderNumber: project.printPDF.modal.orderDetails['orderNumber'],
        orderCompanyName: project.printPDF.modal.orderDetails['orderCompanyName'],
        orderFirstName: project.printPDF.modal.orderDetails['orderFirstName'],
        orderLastName: project.printPDF.modal.orderDetails['orderLastName'],
        orderEmailAddress: project.printPDF.modal.orderDetails['orderEmailAddress'],
        orderAddressLine1: project.printPDF.modal.orderDetails['orderAddressLine1'],
        orderAddressLine2: project.printPDF.modal.orderDetails['orderAddressLine2'],
        orderCity: project.printPDF.modal.orderDetails['orderCity'],
        orderPostcode: project.printPDF.modal.orderDetails['orderPostcode'],
        orderCountry: project.printPDF.modal.orderDetails['orderCountry'],
        orderNotes: project.printPDF.modal.orderDetails['orderNotes'],
        invoicedCountry: project.printPDF.modal.orderDetails['invoicedCountry'],
      };
    }

    generatePDF(
      {
        bearerToken: passportContext.bearerToken,
        projectGUID: project.currentProject.projectGUID,
        cultureCode,
        size,
        headerLayout,
        ...orderDetails,
        fullDownload: type === ProjectEnums.PDFType.Download ? true : false,
        isPrint,
        printArrangement,
      },
      type,
    );
  };

  if (!project || project.currentProject === null || project.currentProject.products.length === 0 || !app) {
    return null;
  }

  const handleDownload = () => {
    if (!project.downloadPDF.projectPdf) {
      clearPDF(ProjectEnums.PDFType.Download);
      return;
    }

    downloadPDF(project.downloadPDF.projectPdf.pdfUrl);
  };

  const handleSendToApproval = () => {
    if (!project || project.currentProject === null || !passportContext.bearerToken || !cultureClaim || !project.printPDF.projectPdf) {
      return;
    }

    const userCultures = allCultureClaims.map(x => {
      // example: role.ftg.sent-to-print-approver.en-gb
      // Get the last index of '.'
      const index = x.value.lastIndexOf('.');

      // + 1 to skip the last '.' and return the culture code
      return x.value.substring(index + 1, x.value.length);
    });

    sendForApproval(
      {
        bearerToken: passportContext.bearerToken,
        cultureCode: cultureClaim.value,
        projectPDFID: project.printPDF.projectPdf.projectPDFID,
      },
      project.currentProject.projectGUID,
      userCultures,
    );
  };

  return (
    <Fragment>
      <Modal
        visible={project.printPDF.modal.isOpen}
        onClose={() => handleCloseGeneratePdf(ProjectEnums.PDFType.SendToPrinters)}
        showClose={true}
      >
        <PdfPrintSettings
          app={app}
          project={project}
          onSelectedSettings={handleGeneratePDF}
          onSubmit={handleSendToApproval}
          setOrderDetails={setOrderDetails}
        />
      </Modal>
      <Modal
        visible={project.downloadPDF.modal.isOpen}
        onClose={() => handleCloseGeneratePdf(ProjectEnums.PDFType.Download)}
        showClose={true}
      >
        <PdfDownloadSettings
          app={app}
          project={project}
          onSelectedSettings={handleGeneratePDF}
          onSubmit={handleSendToApproval}
          onDownload={handleDownload}
          setOrderDetails={setOrderDetails}
        />
      </Modal>

      <div className="selected-products__footer">
        <button
          className="btn btn--inline selected-products__download"
          onClick={() => handleShowGeneratePdf(ProjectEnums.PDFType.Download)}
        >
          Download
        </button>
        <button
          className="btn btn--inline selected-products__download"
          onClick={() => handleShowGeneratePdf(ProjectEnums.PDFType.SendToPrinters)}
        >
          Send To Printers
        </button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  project: state.project,
});

const mapDispatchToProps = {
  generatePDF: (params: Api.IGeneratePDFRequest, type: ProjectEnums.PDFType) => projectActionCreators.generatePDF(params, type),
  sendForApproval: (params: Api.ISendForApprovalRequest, projectGuid: string, userCultures: string[]) =>
    projectActionCreators.SendForApproval(params, projectGuid, userCultures),
  setPDFModal: (params: Models.SetPDFModal) => projectActionCreators.setPDFModal(params),
  downloadPDF: (url: string) => projectActionCreators.downloadPDF(url),
  setOrderDetails: (formData: Record<string, string> | null) => projectActionCreators.setOrderDetails(formData),
  clearPDF: (type: ProjectEnums.PDFType) => projectActionCreators.clearPDF(type),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectDetailFooter);
