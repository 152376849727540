import { FETCH_REPORTS_ERROR, FETCH_REPORTS_SUCCESS } from '@core/redux/reports/actions';
import { Api, Models } from '@core/types';
import { ReportType } from '@core/types/api';
import { reportActionCreators } from '@redux/reports';
import { PassportEnums, usePassportContext } from '@tti/passport';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Column, TableOptions } from 'react-table';
import DateFilters from './DateFilters';
import Table from './Table';

interface IProps {
  fetchReports: (params: Api.IFetchReportsRequest) => void;
}

const ReportsTable: FunctionComponent<IProps> = ({ fetchReports }) => {
  const { passportContext, getClaims } = usePassportContext();
  const reportingClaims = getClaims(PassportEnums.ClaimType.Role, passportContext.claims).filter(x =>
    x.value.startsWith(PassportEnums.RoleType.ReportingAccess),
  );
  
  const [reportingData, setReportingData] = useState<Api.IFetchReportsResponse>({printsByCulture: [], printsByUser: [], downloadsByCulture: [], downloadsByUser: []});
  const [dateFrom, setDateFrom] = useState<string>(new Date(new Date().getFullYear(), 0, 1).toISOString().split("T")[0]);
  const [dateTo, setDateTo] = useState<string>((new Date().toISOString().split("T")[0]));

  const makeHeadings = (mappings: Record<string, string>): Column<Models.PrintsByUser>[] => {
    const tableHeadings: Column<Models.PrintsByUser>[] = [];
    Object.keys(mappings).forEach(key => {
      const newHeading: any = {
        Header: mappings[key],
        accessor: key,
      };
      tableHeadings.push(newHeading);
    });
    return tableHeadings;
  };

  const getDateString = (): string => {
    return `${dateFrom} - ${dateTo}`
  }
  
  const makeTableOptions = (key: string, columnMappings: Record<string, string>): TableOptions<Models.PrintsByUser> => {
    let dataToUse: any;
    switch(key) {
      case "printsByCulture":
        dataToUse = reportingData.printsByCulture;
        break;
      case "printsByUser":
        dataToUse = reportingData.printsByUser;
        break;
      case "downloadsByCulture":
        dataToUse = reportingData.downloadsByCulture;
        break;
      case "downloadsByUser":
        dataToUse = reportingData.downloadsByUser;
        break;
    }
    return {
      columns: makeHeadings(columnMappings),
      data: dataToUse
    }
  }

  useEffect(() => {
    if (!passportContext.bearerToken) return;

    async function getReports() {
      const result: any = fetchReports({
        bearerToken: passportContext.bearerToken || '',
        dateFrom: dateFrom,
        dateTo: dateTo
      });

      result.then((x: any) =>{  
        if(x.type === FETCH_REPORTS_SUCCESS) {
          setReportingData(x?.payload);
        } else if(x.type === FETCH_REPORTS_ERROR) {
          console.log("Error: ", x);
        }
      });
    }

    getReports();
  }, [dateFrom, dateTo]);

  if (!reportingClaims) {
    return null;
  }

  return (
    <Fragment>
      <h1>REPORTING</h1>
      <h2>Filters</h2>
        <DateFilters dateFrom={dateFrom} dateTo={dateTo} setDateFrom={setDateFrom} setDateTo={setDateTo}/>
      <hr/>
      {reportingData && <>
        <div className='table__container'>
          <h3>Prints By Culture ({getDateString()})</h3>
          <Table options={makeTableOptions("printsByCulture", {
            region: "Region",
            cultureCode: "Language",
            totalWaitingApproval: "Total Projects Awaiting Approval",
            totalRejected: "Total Projects Rejected",
            totalApproved: "Total Projects Approved",
            totalTagsApproved: "Total Tags Approved",
          })} reportType={ReportType.PrintsByCulture} dateTo={dateTo} dateFrom={dateFrom} />
        
        </div>
        <div className="table__container">
          <h3>Prints By User ({getDateString()})</h3>
          <Table options={makeTableOptions("printsByUser", {
            firstName: "First Name",
            lastName: "Last Name",
            cultureCode: "Culture Code",
            totalWaitingApproval: "Total Projects Awaiting Approval",
            totalRejected: "Total Projects Rejected",
            totalApproved: "Total Projects Approved",
            totalTagsApproved: "Total Tags Approved"
          })} reportType={ReportType.PrintsByUser} dateTo={dateTo} dateFrom={dateFrom}/>
        </div>
        <div className="table__container">
          <h3>Downloads By Culture ({getDateString()})</h3>
          <Table options={makeTableOptions("downloadsByCulture", {
            cultureCode: "Culture Code",
            totalDownloads: "Total Projects Downloaded"
          })} reportType={ReportType.DownloadsByCulture} dateTo={dateTo} dateFrom={dateFrom}/>
        </div>
        <div className="table__container">
          <h3>Downloads By User ({getDateString()})</h3>
          <Table options={makeTableOptions("downloadsByUser", {
            firstName: "First Name",
            lastName: "Last Name",
            cultureCode: "Culture Code",
            totalDownloads: "Total Projects Downloaded"
          })} reportType={ReportType.DownloadsByUser} dateTo={dateTo} dateFrom={dateFrom}/>
        </div>
      </>}
    </Fragment>
  );
};

const mapDispatchToProps = {
  fetchReports: (params: Api.IFetchReportsRequest) => reportActionCreators.fetchReports(params),
};

export default connect(
  null,
  mapDispatchToProps,
)(ReportsTable);
