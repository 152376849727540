export enum Navigation {
  None = 'NONE',
  Import = 'IMPORT',
  Search = 'SEARCH',
  PowerTools = 'POWERTOOLS',
  GardenTools = 'GARDENTOOLS',
  BatteriesChargers = 'BATTERIESCHARGERS',
  Accessories = 'ACCESSORIES',
  HandTools = 'HANDTOOLS',
  OnePlus = 'ONEPLUS',
  OnePlusHP = 'ONEPLUSHP',
  MaxPower = 'MAXPOWER',
  _72v = '_72V',
  LithiumUSB = 'LITHIUMUSB',
  Link = 'LINK',
}
