import React, { FunctionComponent, useMemo, useState } from 'react';
import ClassNames from 'classnames';
import { AppEnums } from '@core/enums';
import { States, Models, Api } from '@core/types';
import { connect } from 'react-redux';
import { ProductRow } from '@components/products';
import { projectActionCreators } from '@redux/projects';
import { usePassportContext } from '@tti/passport';
import { appActionCreators } from '@redux/app';
import { PassportEnums } from '@tti/passport';
import Simplebar from 'simplebar-react';

interface IProps {
  app?: States.IAppState;
  project?: States.IProjectState;
  category: Models.Category | null;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
  setActiveNav: (navigation: string) => void;
  navigationID: AppEnums.Navigation;
}

interface ProductSelectSubCategoryProps {
  category: Models.Category;
  handleAdd: (agilityIds: number[], removeIfExisting?: boolean, removeAll?: boolean) => void;
  handleCollapse: (id: number) => void;
  collapsed: boolean;
  project: States.IProjectState;
}

const ProductSelectSubCategory: FunctionComponent<ProductSelectSubCategoryProps> = ({
  category: c,
  handleAdd,
  project,
  handleCollapse,
  collapsed,
}) => {
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const name = useMemo(() => c.categoryName.replace(/ /g, ''), [c]);

  return (
    <div key={c.categoryName} className="product-select__sub-category-container">
      <div className="product-select__sub-category">
        {c.categoryName}
        <div className="product-select__product-item product-select__options">
          <input
            id={name}
            type="checkbox"
            className="selected-products__select-all--input"
            onChange={() => {
              const isCategoryActive = selectedCategoryIds.includes(c.categoryAgilityID);

              if (!isCategoryActive) {
                setSelectedCategoryIds([...selectedCategoryIds, c.categoryAgilityID]);
              } else {
                setSelectedCategoryIds(selectedCategoryIds.filter(x => x !== c.categoryAgilityID));
              }

              handleAdd(c.childProducts.map(x => x.variantAgilityID), false, isCategoryActive);
            }}
            checked={selectedCategoryIds.includes(c.categoryAgilityID)}
          />
          <label htmlFor={name} className="selected-products__select-all--label">
            Select All
          </label>
          <button
            type="button"
            className="btn btn--small btn--inline btn--collapse"
            onClick={() => handleCollapse(c.categoryAgilityID)}
          >
            <span>{collapsed ? 'Expand Section' : 'Collapse Section'}</span>
          </button>
        </div>
      </div>
      <div className="product-select__products" data-collapsed={collapsed}>
        {c.childProducts
          .sort((a, b) => (a.variantName > b.variantName ? 1 : -1))
          .map(p => {
            if (project.currentProject === null) {
              return null;
            }

            const isSelected = project.currentProject.products.findIndex(x => x.productID === p.variantAgilityID) > -1;

            return (
              <ProductRow
                key={p.variantAgilityID}
                isSelected={isSelected}
                title={p.variantName}
                productAgilityId={p.variantAgilityID}
                onChange={(e: number) => handleAdd([e])}
                isHalf={true}
              />
            );
          })}
      </div>
    </div>
  );
};

const ProductSelect: FunctionComponent<IProps> = ({ app, project, category, updateProject, navigationID }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(PassportEnums.ClaimType.Locality, passportContext.claims);
  const [collapsedIds, setCollapsedIds] = useState<number[]>([]);

  if (!app || !category || !project || project.currentProject === null) {
    return null;
  }

  const productSelectClassNames = ClassNames({
    'product-select': true,
    'is-active': app.activeNavigation === navigationID,
  });

  const handleCollapse = (id: number): void => {
    if (collapsedIds.includes(id)) {
      // remove from state
      const newArray = collapsedIds.filter(x => x !== id);

      // replace state with new filtered array
      setCollapsedIds(newArray);
    } else {
      // add to state
      setCollapsedIds([...collapsedIds, id]);
    }
  };

  const handleAdd = (agilityIds: number[], removeIfExisting = true, removeAll?: boolean): void => {
    if (!project.currentProject || !passportContext.bearerToken || !cultureClaim) {
      return;
    }

    const projectGUID = project.currentProject.projectGUID;

    let projectProducts: Models.ProjectProduct[] = [];

    // Add the existing products
    for (const product of project.currentProject.products) {
      projectProducts.push({
        productID: product.productID,
        quantity: product.quantity,
      });
    }

    // Loop through all the products from the given list
    for (const agilityId of agilityIds) {
      // Get the index in the current project
      const existingProductIndex = project.currentProject.products.findIndex(x => x.productID === agilityId);

      if (removeAll) {
        // remove all products matching ID
        projectProducts = projectProducts.filter(x => !agilityIds.includes(x.productID));
      } else {
        // add them in if they aren't already in there
        if (existingProductIndex === -1) {
          projectProducts.push({
            productID: agilityId,
            quantity: 1,
          });
        } else if (removeIfExisting) {
          // else we want to remove it
          projectProducts.splice(existingProductIndex, 1);
        }
      }
    }

    // Add all the products
    updateProject({
      projectGUID,
      projectProducts,
      bearerToken: passportContext.bearerToken,
      cultureCode: 'en-TT',
      refetchProject: true,
    });
  };

  return (
    <div className={productSelectClassNames} onClick={e => e.stopPropagation()}>
      <Simplebar className="product-select__container">
        {project &&
          app.activeNavigation === navigationID &&
          category.childCategories.map((category, i) => (
            <ProductSelectSubCategory
              key={`ProductSelect_${category.categoryName.replace(/ /g, '')}_${navigationID}_${i}`}
              category={category}
              handleAdd={handleAdd}
              project={project}
              handleCollapse={handleCollapse}
              collapsed={collapsedIds.includes(category.categoryAgilityID)}
            />
          ))}
      </Simplebar>
    </div>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  app: state.app,
});

const mapDispatchToProps = {
  updateProject: (params: Api.IUpdateProjectRequest) => projectActionCreators.updateProject(params),
  setActiveNav: (navigation: string) => appActionCreators.setActiveNav(navigation),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductSelect);
