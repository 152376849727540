import { Dispatch, AnyAction } from 'redux';
import { Api, Models } from '@core/types';
import {
  FETCH_NAVIGATION,
  FETCH_NAVIGATION_ERROR,
  FETCH_NAVIGATION_SUCCESS,
  FETCH_PROJECT_PRODUCTS,
  FETCH_PROJECT_PRODUCTS_ERROR,
  FETCH_PROJECT_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_SUCCESS,
  IMPORT_BY_ARTICLE_NUMBER,
  IMPORT_BY_ARTICLE_NUMBER_ERROR,
  IMPORT_BY_ARTICLE_NUMBER_SUCCESS,
} from './actions';
import { ApiService, ProductService } from '@core/services';
import { CategoryEnums } from '@core/enums';
import { ThunkDispatch } from 'redux-thunk';
import { AgilityIDs } from '@core/enums/categories';

export const fetchProjectProducts = (params: Api.IFetchProductsRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PROJECT_PRODUCTS,
  });

  
  if (params.variantAgilityIDs?.length === 0) {
    // dispatch an empty response, we don't want to do an api call
    return dispatch({
      type: FETCH_PROJECT_PRODUCTS_SUCCESS,
      payload: {
        categories: [],
      },
    });
  }

  console.log(params)

  // Do the api request
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: FETCH_PROJECT_PRODUCTS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response
  dispatch({
    type: FETCH_PROJECT_PRODUCTS_SUCCESS,
    payload: {
      categories: response.data || [],
    },
  });
};

async function fetchNavigationItem(params: Api.IFetchProductsRequest) {
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  return response;
}

export const fetchNavigation = (bearerToken: string, cultureCode: string) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_NAVIGATION,
  });

  // Fetch all of the navigation tiems
  const [
    powerToolsResponse, gardenToolsResponse, batteriesChargersResponse, accessoriesResponse, handToolsResponse,
    onePlusResponse, onePlusHPResponse, maxPowerResponse, _72vResponse, lithiumUSBResponse, linkResponse
  ] = await Promise.all([
    fetchNavigationItem({ bearerToken, cultureCode, categoryAgilityIDs: [CategoryEnums.AgilityIDs.PowerTools] }),
    fetchNavigationItem({ bearerToken, cultureCode, categoryAgilityIDs: [CategoryEnums.AgilityIDs.GardenTools] }),
    fetchNavigationItem({ bearerToken, cultureCode, categoryAgilityIDs: [CategoryEnums.AgilityIDs.BatteriesChargers] }),
    fetchNavigationItem({ bearerToken, cultureCode, categoryAgilityIDs: [CategoryEnums.AgilityIDs.Accessories] }),
    fetchNavigationItem({ bearerToken, cultureCode, categoryAgilityIDs: [CategoryEnums.AgilityIDs.HandTools] }),
    
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs.onePlus] }),
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs.onePlusHP] }),
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs.maxPower] }),
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs._72v] }),
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs.lithiumUSB] }),
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs.link] }),
  ]);

  const errors: Api.IApiError[] = [];

  if (powerToolsResponse.errors.length) {
    errors.push(...powerToolsResponse.errors);
  }

  if (gardenToolsResponse.errors.length) {
    errors.push(...gardenToolsResponse.errors);
  }

  if (batteriesChargersResponse.errors.length) {
    errors.push(...batteriesChargersResponse.errors);
  }

  if (accessoriesResponse.errors.length) {
    errors.push(...accessoriesResponse.errors);
  }

  if (handToolsResponse.errors.length) {
    errors.push(...handToolsResponse.errors);
  }

  if (onePlusResponse.errors.length) {
    errors.push(...onePlusResponse.errors);
  }

  if (onePlusHPResponse.errors.length) {
    errors.push(...onePlusHPResponse.errors);
  }

  if (maxPowerResponse.errors.length) {
    errors.push(...maxPowerResponse.errors);
  }

  if (_72vResponse.errors.length) {
    errors.push(..._72vResponse.errors);
  }

  if (lithiumUSBResponse.errors.length) {
    errors.push(...lithiumUSBResponse.errors);
  }

  if (linkResponse.errors.length) {
    errors.push(...linkResponse.errors);
  }

  if (errors.length > 0) {
    return dispatch({
      type: FETCH_NAVIGATION_ERROR,
      payload: errors,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_NAVIGATION_SUCCESS,
    payload: {
      powerTools: powerToolsResponse.data && powerToolsResponse.data[0],
      gardenTools: gardenToolsResponse.data && gardenToolsResponse.data[0],
      batteriesChargers: batteriesChargersResponse.data && batteriesChargersResponse.data[0],
      accessories: accessoriesResponse.data && accessoriesResponse.data[0],
      handTools: handToolsResponse.data && handToolsResponse.data[0],
      onePlus: onePlusResponse.data && GetCategories(onePlusResponse.data),
      onePlusHP: onePlusHPResponse.data && GetCategories(onePlusHPResponse.data),
      maxPower: maxPowerResponse.data && GetCategories(maxPowerResponse.data),
      _72v: _72vResponse.data && GetCategories(_72vResponse.data),
      lithiumUSB: lithiumUSBResponse.data && GetCategories(lithiumUSBResponse.data),
      link: linkResponse.data && GetCategories(linkResponse.data),
    },
  });
};

function GetCategories(categories: Models.Category[]): Models.Category {
  let topLevelCategory: Models.Category = categories[0];
  
  categories.forEach(category => {
    if (category.categoryAgilityID !== topLevelCategory.categoryAgilityID) {
      topLevelCategory.childCategories = topLevelCategory.childCategories.concat(category.childCategories);    
    }
  });

  return topLevelCategory;  
}

export const searchProducts = (params: Api.IFetchProductsRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: SEARCH_PRODUCTS,
  });

  // Do the api request
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: SEARCH_PRODUCTS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response
  dispatch({
    type: SEARCH_PRODUCTS_SUCCESS,
    payload: {
      categories: response.data || [],
    },
  });
};

export const importByArticleNumber = (params: Api.IFetchProductsRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: IMPORT_BY_ARTICLE_NUMBER,
  });

  // Do the api request
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: IMPORT_BY_ARTICLE_NUMBER_ERROR,
      payload: response.errors,
    });
  }

  const flattenedResults = ProductService.flatten(response.data || []);

  const unmatchedProducts = ProductService.findMissingArticleNumbers(flattenedResults, params.articleNumbers || []);

  // dispatch the final response
  dispatch({
    type: IMPORT_BY_ARTICLE_NUMBER_SUCCESS,
    payload: {
      importResults: flattenedResults,
      importErrors: unmatchedProducts,
    },
  });
};
