/**
 * Checks if the current client is IE.
 */
export function isIE() {
  /* eslint-disable */
  if (
    navigator.appName == 'Microsoft Internet Explorer' ||
    !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))
  ) {
    return true;
  }
  /* eslint-enable */
  return false;
}

export function areEqual(a: number[] | null, b: number[] | null): boolean {
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  }

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  a.sort((x, y) => x - y);
  b.sort((x, y) => x - y);

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
}
