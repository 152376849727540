import { Api, Models } from '@core/types';
import { Dispatch, AnyAction } from 'redux';
import { FETCH_REPORTS, FETCH_REPORTS_ERROR, FETCH_REPORTS_SUCCESS } from './actions';
import { ApiService } from '@core/services';
import { ThunkDispatch } from 'redux-thunk';
import { ProjectEnums } from '@core/enums';

export const fetchReports = (params: Api.IFetchReportsRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders

  dispatch({
    type: FETCH_REPORTS,
  });

  const response = await ApiService.request<Api.IFetchReportsResponse>({
    controller: 'reporting',
    method: Api.Method.GET,
    slug: 'generate-reports',
    bearerToken: params.bearerToken,
    params: {
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
    },
  });

  if (!response.success || response.errors.length > 0 || !response.data) {
    return dispatch({
      type: FETCH_REPORTS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_REPORTS_SUCCESS,
    payload: {
      printsByUser: response.data.printsByUser,
      printsByCulture: response.data.printsByCulture,
      downloadsByUser: response.data.downloadsByUser,
      downloadsByCulture: response.data.downloadsByCulture,
    },
  });
};
