import React, { FunctionComponent } from 'react';

interface Props {
  id: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  multiLine?: boolean;
  required?: boolean;
  valid?: boolean;
}

const Textbox: FunctionComponent<Props> = ({ id, value, placeholder, onChange, multiLine, required = false, valid = true }) => {
  return (
    <div className={`form__row ${multiLine ? 'form__row--full' : ''} ${!valid ? 'invalid' : ''}`}>
      {multiLine && (
        <textarea
          id={id}
          placeholder={`${placeholder} ${required ? '*' : ''}`}
          className="form__control"
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
        />
      )}
      {!multiLine && (
        <input
          type="text"
          id={id}
          placeholder={`${placeholder} ${required ? '*' : ''}`}
          className="form__control"
          value={value}
          onChange={e => onChange(e.currentTarget.value)}
        />
      )}
      <label htmlFor={id} className="form__label">
        {`${placeholder} ${required ? '*' : ''}`}
      </label>
    </div>
  );
};

export default Textbox;
