import React, { FunctionComponent } from 'react';

interface IProps {
  title: string;
  url: string;
  date: string;
  description: string;
}

const PDFRow: FunctionComponent<IProps> = ({ title, url, date, description }) => {
  return (
    <a rel="noopener noreferrer" href={url} target="_blank" className="project-item project-item--link">
      <div className="project-item__container">
        <div className="project-item__content">
          <div className="project-item__title">{title}</div>
          <div className="project-item__date">{date}</div>
          <div className="project-item__date">{description}</div>
        </div>
      </div>
    </a>
  );
};

export default PDFRow;
