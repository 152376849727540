import React, { useState, Dispatch, FunctionComponent, Fragment } from 'react';
import { usePassportContext } from '@tti/passport';
import classNames from 'classnames';
import { Api, States } from '@core/types';
import { projectActionCreators } from '@redux/projects';
import { connect } from 'react-redux';
import { Modal } from '@components/shared';

interface IProps {
  visible: boolean;
  setIsVisible: Dispatch<boolean>;
  createProject: (params: Api.ICreateProjectRequest) => void;
}

const CreateProject: FunctionComponent<IProps> = ({ visible, setIsVisible, createProject }) => {
  const [projectName, setProjectName] = useState('');
  const { passportContext } = usePassportContext();

  const buttonClassNames = classNames({
    btn: true,
    'btn--disabled': projectName.length <= 2,
  });

  const handleCreateProject = () => {
    if (projectName.length <= 2) {
      return;
    }

    // Create the project
    createProject({
      bearerToken: passportContext.bearerToken || '',
      projectName,
    });

    // Empty the project name
    setProjectName('');

    // Hide the form
    setIsVisible(false);
  };

  return (
    <Modal visible={visible} onClose={() => setIsVisible(false)}>
      <Fragment>
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Create New Project</h2>
            <p className="align--center">Please enter a name for your new project.</p>
            <div className="form__row">
              <div className="form__row">
                <input
                  type="text"
                  id="txtProjectName"
                  placeholder="Project Name"
                  className="form__control"
                  value={projectName}
                  onChange={e => setProjectName(e.target.value)}
                  onKeyDown={e => e.keyCode === 13 && handleCreateProject()}
                ></input>
                <label htmlFor="txtProjectName" className="form__label">
                  Project Name
                </label>
              </div>
            </div>
          </div>
        </div>
        <button className={buttonClassNames} type="button" onClick={handleCreateProject}>
          Create New Project
        </button>
      </Fragment>
    </Modal>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
});

const mapDispatchToProps = {
  createProject: (params: Api.ICreateProjectRequest) => projectActionCreators.createProject(params),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateProject);
