import '@core/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/app';
import * as serviceWorker from './serviceWorker';
import { isIE } from '@core/utils';
import LegacyWarning from '@components/shared/LegacyWarning';

if (isIE() || !window.matchMedia('(min-width: 768px)').matches) {
  ReactDOM.render(<LegacyWarning />, document.getElementById('root'));
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
